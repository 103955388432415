import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Empty,
  Result,
  Layout,
  Card,
  Button,
  PageHeader,
} from 'antd';
import {
  PlusOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

import ConnectionTable from './ConnectionTable/connectionTable';
import CreateConnections from './CreateConnections/createConnections';
import { ConnectionsActions, TagsActions } from '../../actions';

function Connections() {
  const { Header, Content, Footer } = Layout;
  const dispatch = useDispatch();

  const { tagsList } = useSelector((state) => state.TagsReducer);
  const {
    connectionList,
    totalConnections,
    loadingSearch,
    firstLoading,
  } = useSelector((state) => state.ConnectionsReducer);
  const [createConnectionModal, setCreateConnectionModal] = useState(false);

  useEffect(() => {
    if (connectionList.length < 1 && firstLoading) {
      dispatch(ConnectionsActions.async_get_connections(10, 0));
    }
  }, [connectionList, loadingSearch, firstLoading]);

  useEffect(() => {
    if (tagsList.length < 1) {
      dispatch(TagsActions.async_get_all_tags(100, 0));
    }
  }, [tagsList]);

  return (
    <Layout>
      <Header className="site-layout-background" style={{ background: '#FFF' }}>
        <PageHeader
          className="site-page-header"
          title="Conexões"
          extra={[
            <Button type="primary" onClick={() => setCreateConnectionModal(true)}>
              <PlusOutlined />
              Adicionar conexão
            </Button>,
          ]}
        />
      </Header>

      <Content>
        <Card
          bordered={false}
          style={{
            margin: 'auto', width: '100%', minHeight: '100vh',
          }}
        >
          {
            connectionList.length > 0
              ? (
                <ConnectionTable
                  dataSource={connectionList}
                  totalItems={totalConnections}
                />
              )
              : (
                <Card bordered={false}>
                  {firstLoading === true
                    ? <Result icon={<LoadingOutlined />} />
                    : <Empty description="Sem conexões" />}
                </Card>
              )
}
          <CreateConnections
            visible={createConnectionModal}
            onCancel={() => setCreateConnectionModal(false)}
          />
        </Card>
      </Content>

      <Footer />
    </Layout>
  );
}

export default Connections;
