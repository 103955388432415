import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Highlighter from 'react-highlight-words';
import {
  Table,
  Modal,
  Button,
  Space,
  Input,
  Tag,
  Card,
  Tooltip,
  Dropdown,
  Menu,
} from 'antd';
import {
  ExclamationCircleOutlined,
  SearchOutlined,
  DeleteOutlined,
  UserAddOutlined,
  MoreOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import { UsersActions } from '../../../../actions';
import '../../styles.css';

function UsersTable() {
  const dispatch = useDispatch();
  const { collapsed } = useSelector((state) => state.SiderReducer);
  const { usersList, loadMoreUsers } = useSelector((state) => state.UsersReducers);

  const [newClass, setNewClass] = useState('');
  const [formValues, setFormValues] = useState({});
  const [activeDevices, setActiveDevices] = useState(0);
  const [userSelected, setUserSelected] = useState([]);
  const [state, setState] = useState({
    searchText: '',
    searchedColumn: '',
    visible: false,
    clientName: '',
    clientEmail: '',
  });
  const [params, setParams] = useState({ limit: 100, offset: 0 });
  const { limit, offset } = params;

  const handleDeleteUser = (arrayOfUsers, { id, name }) => {
    const numberOfUsers = arrayOfUsers.length;
    const listOfUsers = numberOfUsers === 0 ? [id]
      : arrayOfUsers.map((user) => user.id);

    const confirmMessage = numberOfUsers > 1
      ? `Deseja deletar os ${numberOfUsers} usuários selecionados?`
      : `Deseja deletar o usuário ${name}?`;

    Modal.confirm({
      title: confirmMessage,
      icon: <ExclamationCircleOutlined />,

      onOk() {
        dispatch(UsersActions.async_delete_user(listOfUsers));
      },
    });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: '' });
  };

  const getFilterClients = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Procurar
          </Button>

          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Cancelar
          </Button>
        </Space>
      </div>
    ),

    filterIcon: () => <SearchOutlined style={{ color: '#1890ff' }} />,

    onFilter: (value, record) => (record[dataIndex]
      ? record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
      : ''),

    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => state.searchInput.select(), 100);
      }
    },

    render: (text) => (state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[state.searchText]}
        autoEscape
        // eslint-disable-next-line react/destructuring-assignment
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )),
  });

  const menu = () => (
    <Menu>
      <Menu.Item
        key="edit-user"
        onClick={() => dispatch(UsersActions.enable_update_user_modal(
          true, formValues.id, formValues,
        ))}
      >
        Editar
      </Menu.Item>

      <Menu.Item
        key="delete-device"
        onClick={() => handleDeleteUser(userSelected, formValues)}
      >
        Deletar
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: 'ID do usuário',
      dataIndex: 'id',
      key: 'id',
      width: 80,
      ...getFilterClients('id'),
    },

    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      width: 80,
      ...getFilterClients('name'),
    },

    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      width: 100,
      render: (is_active) => (
        <>
          {is_active ? (
            <Tag color="green">Ativado</Tag>
          ) : (
            <Tag color="red">Desativado</Tag>
          )}
        </>
      ),
    },

    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 80,
      ...getFilterClients('email'),
    },

    {
      title: 'ID do Cliente',
      dataIndex: 'client_id',
      key: 'client_id',
      width: 70,
      ...getFilterClients('client_id'),
      render: (client_id) => client_id || '--',
    },
    {
      title: 'Categoria',
      dataIndex: 'user_type_id',
      key: 'user_type_id',
      width: 80,
      // ...getFilterClients('user_type_id'),
      render: (...record) => (
        <div className="actions">
          {record[1].user_type_id === 1 && <Tag color="red">{'Administrador'.replace(/["]/g, '')}</Tag>}
          {record[1].user_type_id === 2 && <Tag color="purple">{'Cliente'.replace(/["]/g, '')}</Tag>}
          {record[1].user_type_id === 3 && <Tag color="cyan">{'Provedor'.replace(/["]/g, '')}</Tag>}

          <Tooltip title="Opções">
            <Dropdown
              overlay={menu(record[1])}
              trigger={['click']}
              onClick={() => setFormValues(record[1])}
            >
              <MoreOutlined style={{ color: '#1890ff', fontSize: '25px' }} />
            </Dropdown>
          </Tooltip>
        </div>
      ),
    },
  ];

  const rowSelection = {
    onChange: (_, selectedRows) => {
      setUserSelected(selectedRows);
    },
  };

  useEffect(() => {
    if (loadMoreUsers) {
      setParams({ limit: 100, offset: offset + limit });
      dispatch(UsersActions.async_get_all_users(limit, offset));
      dispatch(UsersActions.load_more_users(false));
    }

    const users_active = usersList.filter((user) => user.is_active).length;
    setActiveDevices(users_active);

    if (!collapsed) {
      setNewClass('footer-opened');
    } else {
      setNewClass('footer-closed');
    }
  }, [dispatch, formValues, loadMoreUsers, params, usersList]);

  return (
    <div className="site-card-border-less-wrapper">
      <Card
        title={`Total de ${activeDevices} usuários ativos`}
        bordered={false}
        style={{ width: '100%' }}
        extra={(
          <Space>
            <Button
              type="link"
              onClick={() => dispatch(UsersActions.enable_create_user_modal(true))}
            >
              <UserAddOutlined />
              {' '}
              Novo usuário

            </Button>
          </Space>
          )}
      >

        <Table
          columns={columns}
          rowSelection={rowSelection}
          dataSource={usersList}
          pagination={{ pageSize: 10, position: ['bottomCenter'] }}
          scroll={{ x: 1000 }}
          bordered
          loading={!(usersList.length > 0)}
          footer={() => (
            <>
              <Button
                type="link"
                onClick={() => dispatch(UsersActions.load_more_users(true))}
              >
                <PlusOutlined />
                Carregar mais
              </Button>
            </>
          )}
        />
      </Card>

      <footer
        className={newClass}
        style={{
          visibility: userSelected.length > 0 ? 'visible' : 'hidden',
        }}
      >
        <div className="footer-text-left">
          <div>
            <p>
              Total de
              {' '}
              <strong>{userSelected.length}</strong>
              {' '}
              usuários selecionados
            </p>
          </div>

        </div>

        <div className="footer-btns-right">
          <Button
            danger
            type="primary"
            onClick={() => handleDeleteUser(userSelected, formValues)}
          >
            <DeleteOutlined />
            <span>Deletar</span>
          </Button>
        </div>
      </footer>
    </div>
  );
}

export default UsersTable;
