import { ACTION_TYPES } from '../../actions/messagesActions';

const INITIAL_STATE = {
  messagesList: [],
  onMessageSearch: false,
};

const MessagesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.SAVE_MESSAGES:
      return {
        ...state,
        messagesList: action.messages,
      };

    case ACTION_TYPES.CLEAR_MESSAGES_SEARCH:
      return {
        ...state,
        messagesList: [],
      };

    default:
      return { ...state };
  }
};

export default MessagesReducer;
