import React, { useState, useEffect } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Select,
  Modal,
  InputNumber,
} from 'antd';
import {
  ExclamationCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

import { DevicesActions } from '../../actions';
import EditableTagGroup from '../Devices/register/components/tags';

const DeviceRegister = ({ visible, onCancel }) => {
  const { Option } = Select;
  const { confirm } = Modal;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { createDevice } = useSelector((state) => state.DevicesReducer);
  const { contractsList } = useSelector((state) => state.ContractsReducer);
  const { tagsList } = useSelector((state) => state.TagsReducer);

  const [listOfTags, setListOfTags] = useState(tagsList.map((tag) => tag.name));
  const [activeABP, setAcitveABP] = useState('');
  const [encryptNS, setEncrypNS] = useState('');

  function handleUpdateTags(tags) {
    setListOfTags(tags);
  }

  async function onFinish(values) {
    createDevice.dev_eui = values.dev_eui;
    createDevice.tags = listOfTags || [];
    createDevice.contract_id = values.contract_id;
    createDevice.activation = values.activation;
    createDevice.nwkskey = values.nwkskey || null;
    createDevice.dev_addr = values.dev_addr || null;
    createDevice.app_eui = values.app_eui;
    createDevice.app_key = values.app_key || null;
    createDevice.counters_size = values.counters_size;
    createDevice.dev_class = values.dev_class;
    createDevice.encryption = values.encryption;
    createDevice.appskey = values.appskey || null;
    createDevice.rx1.delay = values.delay;
    createDevice.band = values.band;
    createDevice.strict_counter = values.strict_counter || false;

    confirm({
      title: 'Confirma o cadastro do dispositivo ?',
      icon: <ExclamationCircleOutlined />,
      content: '',

      onOk() {
        dispatch(DevicesActions.async_add_device(createDevice));
      },
    });
  }

  useEffect(() => {
    if (listOfTags.length < 1) {
      setListOfTags(tagsList.map((tag) => tag.name));
    }
  }, [tagsList]);

  return (
    <Modal
      title="Cadastro de dispositivo"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closeIcon={<CloseCircleOutlined style={{ color: 'red' }} />}
      keyboard
      width={500}
      bodyStyle={{ height: '600px' }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        size="large"
        style={{ textAlign: 'left', padding: 30, width: '100%' }}
      >

        <Form.Item
          label="Contrato"
          name="contract_id"
          rules={[
            {
              required: false,
              message: 'Por favor selecione um contrato!',
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Selecione o Contrato"
            optionFilterProp="children"
            filterOption={
              (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}
          >
            {contractsList.map((contract) => (
              <Option key={contract.id} value={contract.id}>
                {contract.name || `contrato_${contract.id}`}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Device EUI"
          name="dev_eui"
          rules={[
            {
              required: true,
              message: 'Por favor adicione o Device EUI!',
            },
          ]}
        >
          <Input
            style={{ width: '100%' }}
            placeholder="3ba4bs9ddb5fad3e"
            maxLength="16"
          />
        </Form.Item>

        <Form.Item
          label="Tags"
          name="tags"
          tooltip={{
            title: 'Em caso do uso de tags, cadastre alguma previamente.',
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: false,
              message: 'Por favor selecione uma tag!',
              type: 'array',
            },
          ]}
        >
          <EditableTagGroup
            key={listOfTags}
            newtags={handleUpdateTags}
            mytags={listOfTags}
          />
        </Form.Item>

        <Form.Item
          label="Ativação"
          name="activation"
          rules={[
            {
              required: true,
              message: 'Por favor selecione a Ativação!',
            },
          ]}
        >
          <Select
            style={{ width: '100%' }}
            placeholder="Ativação"
            onChange={(value) => setAcitveABP(value)}
          >
            <Option key="ABP" value="ABP">ABP</Option>
            <Option key="OTAA" value="OTAA">OTAA</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="NwsKey"
          name="nwkskey"
          rules={[
            {
              required: activeABP === 'ABP',
              message: 'Por favor adicione o NwsKey!',
            },
          ]}
        >
          <Input
            style={{ width: '100%' }}
            disabled={activeABP === 'OTAA'}
            maxLength="32"
          />
        </Form.Item>

        <Form.Item
          label="Dev Address"
          name="dev_addr"
          rules={[
            {
              required: activeABP === 'ABP',
              message: 'Por favor adicione o Device Address!',
            },
          ]}
        >
          <Input
            style={{ width: '100%' }}
            placeholder="4e48713c"
            disabled={activeABP === 'OTAA'}
            maxLength="8"
          />
        </Form.Item>

        <Form.Item
          label="App EUI"
          name="app_eui"
          rules={[
            {
              required: true,
              message: 'Por favor adicione o App EUI!',
            },
          ]}
        >
          <Input
            style={{ width: '100%' }}
            // placeholder="ea744cceabcd1fa30a5de485bde327fa"
            maxLength="16"
          />
        </Form.Item>

        <Form.Item
          label="Counter Size"
          name="counters_size"
          rules={[
            {
              required: true,
              message: 'Por favor selecione o Counter Size!',
            },
          ]}
        >
          <Select style={{ width: '100%' }} placeholder="Counter">
            <Option key="counter_size_4" value={4}>4</Option>
            <Option key="counter_size_2" value={2}>2</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Dev Class"
          name="dev_class"
          rules={[
            {
              required: true,
              message: 'Por favor selecione o Dev Class!',
            },
          ]}
        >
          <Select style={{ width: '100%' }}>
            <Option key="A" value="A">A</Option>
            <Option key="C" value="C">C</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Encriptação"
          name="encryption"
          rules={[
            {
              required: true,
              message: 'Por favor selecione o Encryption!',
            },
          ]}
        >
          <Select
            style={{ width: '100%' }}
            onChange={(value) => setEncrypNS(value)}
          >
            <Option key="APP" value="APP">APP</Option>
            <Option key="NS" value="NS">NS</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="App Session KEY"
          name="appskey"
          rules={[
            {
              required: (encryptNS === 'NS' && activeABP === 'ABP'),
              message: 'Por favor adicione o Apps Key!',
            },
          ]}
        >
          <Input
            style={{ width: '100%' }}
            // placeholder="0a0b524a02f057390a5966e44e352030"
            maxLength="32"
            disabled={!(encryptNS === 'NS' && activeABP === 'ABP')}
          />
        </Form.Item>

        <Form.Item
          label="App Key"
          name="app_key"
          rules={[
            {
              required: (encryptNS === 'NS' && activeABP === 'OTAA'),
              message: 'Por favor adicione o App Key!',
            },
          ]}
        >
          <Input
            style={{ width: '100%' }}
            // placeholder="ec16432923b691c2329a4543e411360a"
            maxLength="32"
            disabled={!(encryptNS === 'NS' && activeABP === 'OTAA')}
          />
        </Form.Item>

        <Form.Item
          label="Banda"
          name="band"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select style={{ width: '100%' }}>
            <Option value="LA915-928A">LA915-928A</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Rx1"
          name="delay"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <InputNumber placeholder="1" min={0} max={100000} />
        </Form.Item>

        <Form.Item label="Strict counter" name="strict_counter">
          <Select defaultValue="False">
            <Option value key="enabled-strict-counter">Habilitado</Option>
            <Option value={false} key="diabled-strict-counter">Desabilitado</Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
            Cadastrar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

DeviceRegister.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default DeviceRegister;
