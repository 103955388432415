import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, BrowserRouter } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import {
  HomeOutlined,
  LogoutOutlined,
  ApiOutlined,
} from '@ant-design/icons';

import SubRoutes from './subRoutes';
import {
  SiderActions,
  AuthActions,
} from '../../actions';
// import ClientRoute from '../../routes/clientRoute';
import ClientPrivateRoute from '../../routes/clientRoute';

function ClientDashboard() {
  const { Sider } = Layout;

  const [newClass, setNewClass] = useState('closed');
  const dispatch = useDispatch();

  //   const { clientsList } = useSelector((state) => state.ClientsReducer);
  //   const { devicesList } = useSelector((state) => state.DevicesReducer);
  //   const { contractsList } = useSelector((state) => state.ContractsReducer);
  const { collapsed } = useSelector((state) => state.SiderReducer);

  // dispara ação para alteração do valor de collapsed
  function handleCollapsed() {
    dispatch(SiderActions.set_collapsed(!collapsed));

    if (collapsed) {
      setNewClass('opened');
    } else {
      setNewClass('closed');
    }
  }

  //   useEffect(() => { }, [clientsList, devicesList, contractsList, dispatch]);

  return (
    <BrowserRouter>
      <div className="dashboard-container">
        <Layout>
          <Sider
            collapsible
            className="side"
            collapsed={collapsed}
            onCollapse={handleCollapsed}
          >
            <Menu theme="dark" mode="inline">
              <Menu.Item key="over-view" title="Geral" icon={<HomeOutlined />}>
                <Link to="/home-view">Home</Link>
              </Menu.Item>

              <Menu.Item key="connections" title="Gerenciar Conexções" icon={<ApiOutlined />}>
                <Link to="/home-view/connections">Gerenciar Conexções</Link>
              </Menu.Item>

              <Menu.Item
                title="Sair"
                icon={<LogoutOutlined />}
                onClick={() => dispatch(AuthActions.logout())}
              >
                <Link to="/login">
                  Sair
                </Link>
              </Menu.Item>
            </Menu>

          </Sider>

          {/* <Header className="site-layout-background" style={{ padding: 0 }}>Header</Header> */}
          <div className={newClass}>
            {SubRoutes.map((DashRoutes) => (
              <ClientPrivateRoute
                key={`${DashRoutes.key}`}
                path={DashRoutes.path}
                exact={DashRoutes.exact}
                component={DashRoutes.component}
              />
            ))}
          </div>
        </Layout>

      </div>
    </BrowserRouter>
  );
}

export default ClientDashboard;
