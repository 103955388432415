export const isValidDeviceEui = (value) => {
  if (!value) return value;

  let devEuiFormated = value.replace(/[ ,*-+=_()^~ªº´`<>|{}.:;?!@#$%&]/g, '');

  if (devEuiFormated.length !== 16) return false;

  devEuiFormated = devEuiFormated.toLowerCase();

  return /[a-f\d]/.test(devEuiFormated);
};
