/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Card,
  Modal,
  Button,
  Form,
  Input,
  Row,
  InputNumber,
  Select,
  Col,
} from 'antd';
import {
  CloseCircleOutlined,
  SendOutlined,
} from '@ant-design/icons';

import { DevicesActions } from '../../actions';

const DownlinkMessageModal = ({ dev_eui, visible, onCancel }) => {
  const { TextArea } = Input;
  const { Option } = Select;

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  async function handleSendDownlinkMessage({ payload, port, confirmed }) {
    dispatch(DevicesActions.async_send_downlink_message(dev_eui, payload, port, confirmed));
    onCancel();
    form.resetFields();
  }

  return (
    <Modal
      title={`Envio de downlink para ${dev_eui}`}
      key={`send-downlin-messages-${dev_eui}`}
      visible={visible}
      onCancel={onCancel}
      closeIcon={<CloseCircleOutlined style={{ color: 'red' }} />}
      footer={null}
    >
      <Card bordered={false}>
        <Form form={form} onFinish={handleSendDownlinkMessage}>
          <Form.Item label="Payload" name="payload">
            <TextArea autoSize={{ minRows: 1, maxRows: 6 }} maxLength={256} />
          </Form.Item>

          <Form.Item label="Porta" name="port">
            <InputNumber min={0} style={{ width: '50%' }} />
          </Form.Item>

          <Form.Item label="Confirmação" name="confirmed">
            <Select defaultValue>
              <Option key="confirmed" value>Sim</Option>
              <Option key="unconfirmed" value={false}>Não</Option>
            </Select>
          </Form.Item>

          <Row gutter={16}>
            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%' }}
                >
                  <SendOutlined />
                  Enviar
                </Button>
              </Form.Item>
            </Col>

            <Col>
              <Form.Item>
                <Button
                  onClick={onCancel}
                  style={{ width: '100%' }}
                >
                  Cancelar
                </Button>
              </Form.Item>
            </Col>
          </Row>

        </Form>
      </Card>
    </Modal>
  );
};

DownlinkMessageModal.propTypes = {
  dev_eui: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default DownlinkMessageModal;
