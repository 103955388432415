import React, { useState } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import PropTypes from 'prop-types';
import {
  Form,
  Modal,
  Input,
  Button,
  Select,
  Layout,
  Divider,
  Tag,
  Space,
  Typography,
  Switch,
} from 'antd';
import {
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import { ConnectionsActions } from '../../../actions';
import { EditableGroup } from '../../../components';

function CreateConnections({ visible, onCancel }) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { confirm } = Modal;
  const { Content } = Layout;
  const { CheckableTag } = Tag;
  const dispatch = useDispatch();

  const { u_type } = useSelector((state) => state.AuthReducer);
  const { clientsList } = useSelector((state) => state.ClientsReducer);
  const { tagsList } = useSelector((state) => state.TagsReducer);

  const [connectionType, setConnectionType] = useState('mqtt');
  const [tagsSelected, setTagsSelected] = useState([]);
  const [optionsSelected, setOptionsSelected] = useState([]);
  const [appEuiList, setAppEuiList] = useState([]);
  const [gatewaysList, setGatewaysList] = useState([]);
  const [devices, setDevices] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [items, setItems] = useState(tagsList.map((tag) => tag.name));
  const [name, setName] = useState('');
  let index = 0;
  const messageTypes = ['join_request', 'uplink', 'downlink', 'error', 'warning', 'info', 'location', 'status_response', 'downlink_request'];
  const AditionalOptions = ['Duplicates', 'Radio data', 'Lora data', 'Incluir tags'];

  async function onFinish(values) {
    confirm({
      title: 'Deseja confirmar o registro da conexão?',
      icon: <ExclamationCircleOutlined />,

      onOk() {
        const connection_data = {
          auth_header: values.auth_header || null,
          url: values.url || null,
          client_id_label: values.client_id_label || null,
          topic_down: values.topic_down || null,
          topic_up: values.topic_up || null,
          uri: values.uri || null,
          connection_type: values.connection_type || connectionType,
          description: values.connection_description,
        };

        const filter_data = {
          applications: appEuiList || [],
          description: values.filter_description || null,
          devices: devices || [],
          duplicate: optionsSelected.includes('Duplicates'),
          gateways: gatewaysList || [],
          lora: optionsSelected.includes('Lora data'),
          radio: optionsSelected.includes('Radio data'),
          tags: values.tags || [],
          types: tagsSelected,
          with_tags: optionsSelected.includes('Incluir tags'),
          is_disabled: isDisabled,
        };

        setTagsSelected([]);
        setOptionsSelected([]);
        setAppEuiList([]);
        setGatewaysList([]);

        const client_id = values.client_id || null;
        dispatch(ConnectionsActions.async_add_connection(
          connection_data, filter_data, client_id,
        ));
        form.resetFields();
        onCancel();
      },
    });
  }

  function handleTagChange(tag, checked) {
    const nextSelectedTags = checked
      ? [...tagsSelected, tag] : tagsSelected.filter((t) => t !== tag);
    setTagsSelected(nextSelectedTags);
  }

  function handleAditionalOptionChange(item, checked) {
    const nextSelectedTags = checked
      ? [...optionsSelected, item] : optionsSelected.filter((t) => t !== item);
    setOptionsSelected(nextSelectedTags);
  }

  const handleChange = (value) => {
    setConnectionType(value);
  };

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const addItem = (e) => {
    e.preventDefault();
    index += 1;
    setItems([...items, name || `New item ${index}`]);
    setName('');
  };

  function filterClientsTags(value) {
    try {
      const clientsTags = tagsList.filter((i) => i.client_id === value).map((k) => k.name);
      setItems(clientsTags);
    } catch (error) {
      //
    }
  }

  return (
    <Modal
      title="Nova conexão"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closeIcon={<CloseCircleOutlined style={{ color: 'red' }} />}
      keyboard
      width={500}
    >
      <Layout style={{ background: '#FFFF' }}>
        <Content>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={{}}
          >
            { (u_type === 1 || u_type === '1')
             && (
             <Form.Item
               label="Cliente"
               name="client_id"
               rules={[
                 {
                   required: (u_type === 1 || u_type === '1'),
                   message: 'Este campo é obrigatório',
                 },
               ]}
             >
               <Select
                 showSearch
                 allowClear
                 style={{ minWidth: 200 }}
                 onChange={filterClientsTags}
                 optionFilterProp="children"
                 filterOption={
                  (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}
               >
                 {clientsList.map(
                   (client) => (
                     <Option key={client.id} value={client.id}>
                       {client.company_name}
                     </Option>
                   ),
                 )}
               </Select>
             </Form.Item>
             )}

            <div className="space-align-block">
              <Space align="baseline">
                <h2>Detalhes do filtro</h2>
                <Switch
                  checkedChildren="Habilitado"
                  unCheckedChildren="Desabilitado"
                  defaultChecked
                  onChange={(checked) => setIsDisabled(!checked)}
                />
              </Space>
            </div>

            <Form.Item
              label="Descrição"
              name="filter_description"
              requiredMark="opcional"
            >
              <Input style={{ width: '50%' }} />
            </Form.Item>

            <Form.Item
              label="Tags"
              name="tags"
            >
              <Select
                mode="tags"
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <Space align="center" style={{ padding: '0 8px 4px' }}>
                      <Input placeholder="Please enter item" value={name} onChange={onNameChange} />
                      <Typography.Link onClick={addItem} style={{ whiteSpace: 'nowrap' }}>
                        <PlusOutlined />
                        {' '}
                        Adicionar tag
                      </Typography.Link>
                    </Space>
                  </>
                )}
              >
                {items.map((item) => (
                  <Option key={item}>{item}</Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Dispositivos"
              name="devices"
              key="devices"
            >
              <EditableGroup
                key="editable-devices-group"
                updateHandler={(item) => setDevices(item)}
                items={devices}
              />
            </Form.Item>

            <Form.Item
              label="Aplicações"
              name="applications"
            >
              <EditableGroup
                key="editable--appeui-group"
                updateHandler={(item) => setAppEuiList(item)}
                items={appEuiList}
              />
            </Form.Item>

            <Form.Item
              label="Gateways"
              name="gateways"
            >
              <EditableGroup
                key="editable-gatway-group"
                updateHandler={(item) => setGatewaysList(item)}
                items={gatewaysList}
              />
            </Form.Item>

            <Form.Item
              label="Tipos de mensagens"
              name="type"
            >
              {messageTypes.map((messageType) => (
                <CheckableTag
                  key={messageType}
                  checked={tagsSelected.indexOf(messageType) > -1}
                  onChange={(checked) => handleTagChange(messageType, checked)}
                >
                  {messageType}
                </CheckableTag>
              ))}
            </Form.Item>

            <Form.Item label="Opções" name="options">
              {AditionalOptions.map((option) => (
                <CheckableTag
                  key={option}
                  checked={optionsSelected.indexOf(option) > -1}
                  onChange={(checked) => handleAditionalOptionChange(option, checked)}
                >
                  {option}
                </CheckableTag>
              ))}
            </Form.Item>

            <Divider />

            <h2>Detalhes da conexão</h2>

            <Form.Item
              label="Tipo da conexão"
              name="connection_type"
            >
              <Select onChange={handleChange} defaultValue="mqtt">
                <Option key="mqtt" value="mqtt">MQTT</Option>
                <Option key="http2" value="http2">HTTP</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Descrição"
              name="connection_description"
              requiredMark="opcional"
            >
              <Input style={{ width: '50%' }} />
            </Form.Item>

            <Form.Item
              label="URL da aplicação"
              name="url"
              hidden={connectionType === 'mqtt'}
              tooltip="Você pode anexar o link da sua aplicação."
              rules={[
                {
                  required: connectionType === 'http2',
                },
              ]}
            >
              <Input placeholder="https://exemplo.com" />
            </Form.Item>

            <Form.Item
              label="Authorization header"
              name="auth_header"
              hidden={connectionType === 'mqtt'}
              requiredMark="opcional"
            >
              <Input placeholder="Base YWxhZGRpbjpvcGVuc2zYW1j" maxLength={24} />
            </Form.Item>

            <Form.Item
              label="Network Server publish topic"
              name="topic_up"
              hidden={connectionType === 'http2'}
              rules={[
                {
                  required: connectionType === 'mqtt',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="URI"
              name="uri"
              hidden={connectionType === 'http2'}
              rules={[
                {
                  required: connectionType === 'mqtt',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Network Server subscribe topic"
              name="topic_down"
              requiredMark="opcional"
              hidden={connectionType === 'http2'}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Client id"
              name="client_id_label"
              requiredMark="opcional"
              hidden={connectionType === 'http2'}
            >
              <Input />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: '100%' }}
              >
                Registrar
              </Button>
            </Form.Item>
          </Form>

        </Content>
      </Layout>
    </Modal>
  );
}

CreateConnections.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CreateConnections;
