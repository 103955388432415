import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Button,
  Form,
  Row,
  Col,
  Input,
  Space,
  DatePicker,
  InputNumber,
  Select,
  Switch,
} from 'antd';

import { MessagesActions } from '../../actions';
import { isValidDeviceEui } from '../../helpers/validators';

function RetrieveMessagesForm({ onCleanFilters }) {
  const { openMessageModal } = useSelector((state) => state.DevicesReducer);

  const dispatch = useDispatch();
  const [enablePulling, setEnablePulling] = useState(false);
  const [pullMessages, setPullMessages] = useState();
  const [messageForm, setMessageForm] = useState({
    devEui: '',
    limit: 10,
    messageType: null,
  });

  const [form] = Form.useForm();
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  const dateFormat = 'YYYY-MM-DD HH:mm';
  const messageTypes = [
    'uplink',
    'downlink_request',
    'downlink',
    'error',
    'status_response',
    'join_request',
  ];

  function disableDate(current) {
    return current && current < moment().subtract(3, 'months');
  }

  function handleSubmit({
    dev_eui, range_date, limit, message_type,
  }) {
    const [initial_date, final_date] = range_date || [undefined, moment()];

    const parsed_inital_date = initial_date ? initial_date.format('YYYY-MM-DD HH:mm') : initial_date;
    const parsed_final_date = final_date.format('YYYY-MM-DD HH:mm') || final_date;

    dispatch(MessagesActions.async_get_messages(dev_eui, {
      message_type: message_type || null,
      limit: limit || 10,
      initial_date: parsed_inital_date,
      final_date: parsed_final_date,
    }));
  }

  const handleOnChange = (checked) => {
    setEnablePulling(checked);
  };

  const handlePooling = () => {
    if (messageForm.devEui) {
      dispatch(MessagesActions.async_get_messages(messageForm.devEui, {
        limit: messageForm.limit,
        final_date: moment().format(dateFormat),
        message_type: messageForm.messageType,
      }));
    }
  };

  useEffect(() => {
    if (enablePulling && openMessageModal) {
      setPullMessages(
        setInterval(handlePooling, 5000),
      );
    } else {
      clearInterval(pullMessages);
      setEnablePulling(false);
    }

    if (!openMessageModal) {
      setInterval(onCleanFilters, 500);
      form.resetFields();
    }
  }, [enablePulling, openMessageModal, messageForm]);

  return (
    <Form
      form={form}
      name="advanced_search"
      className="ant-advanced-search-form"
      style={{ width: '100%' }}
      onFinish={handleSubmit}
    >
      <Row>
        <Space align="baseline">
          <Col key="test">
            <Form.Item
              name="dev_eui"
              label="Dev EUI"
              rules={[
                {
                  required: true,
                  message: 'Informe o dev_eui',
                },
                {
                  pattern: /[a-f\d]/g,
                  whitespace: false,
                  message: 'Permitido apenas letras e numeros.',
                },
              ]}
            >
              <Input
                placeholder="abf3d46958131eca"
                maxLength="16"
                onChange={(e) => {
                  setMessageForm({ ...messageForm, devEui: e.target.value });
                  clearInterval(pullMessages);
                }}
                allowClear
              />
            </Form.Item>
          </Col>

          <Col key="date-picker-final">
            <Form.Item name="range_date" label="Intervalo de tempo">
              <RangePicker
                defaultValue={[null, moment(moment(), dateFormat)]}
                format={dateFormat}
                disabledDate={disableDate}
                allowClear
                disabled={enablePulling}
              />
            </Form.Item>
          </Col>

        </Space>
      </Row>

      <Row>
        <Space>
          <Col key="test">
            <Form.Item name="message_type" label="Tipo de mensagem">
              <Select
                style={{ width: 150 }}
                onChange={
                  (value) => {
                    clearInterval(pullMessages);
                    setMessageForm({ ...messageForm, messageType: value });
                  }
}
                allowClear
              >
                {messageTypes.map((messageType) => (
                  <Option value={messageType} key={messageType}>{messageType}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col key="limit">
            <Form.Item
              name="limit"
              label="Limite"
            >
              <InputNumber
                min="1"
                max="100"
                defaultValue="10"
                allowClear
                onChange={(limit) => {
                  clearInterval(pullMessages);
                  setMessageForm({ ...messageForm, limit });
                }}
              />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Pesquisar
              </Button>
            </Form.Item>
          </Col>

          <Col>
            <Form.Item>
              <Button onClick={onCleanFilters}>
                Limpar resultado
              </Button>
            </Form.Item>
          </Col>
        </Space>
      </Row>

      <Row>
        <Space>
          <Col>
            <Form.Item name="live_inspector" valuePropName="checked">
              <Switch
                checkedChildren="live on"
                unCheckedChildren="live off"
                onChange={handleOnChange}
                disabled={!isValidDeviceEui(messageForm.devEui)}
              />
            </Form.Item>
          </Col>
        </Space>
      </Row>
    </Form>
  );
}

RetrieveMessagesForm.propTypes = {
  onCleanFilters: PropTypes.func.isRequired,
};

export default RetrieveMessagesForm;
