import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Input, Form,
  Button,
  Select, Modal, InputNumber,
} from 'antd';
import {
  CloseCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

import { ContractActions } from '../../../actions';

function UpdateForm({ contract_info, visible, onCancel }) {
  const { Option } = Select;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { clientsList } = useSelector((state) => state.ClientsReducer);

  const {
    id,
    plan_id,
    crm_id,
    is_active,
    max_device,
    max_active,
    plan_type,
    day_debit,
    client_id,
    name,
  } = contract_info;

  async function handleUpdateContract(values) {
    setConfirmLoading(true);

    // const debit_day = day_debit.toString();
    // const parsed_debit_day = debit_day.length > 1
    //   ? debit_day
    //   : debit_day.padStart(2, '0');

    dispatch(
      ContractActions.async_update_contract(id, {
        name: values.name,
        plan_id: values.plan_id,
        crm_id: values.crm_id,
        is_active: values.is_active,
        max_device: values.max_device,
        max_active: values.max_active,
        plan_type: values.plan_type,
        day_debit: '05',
        client_id: values.client_id,
      }),
    );

    setConfirmLoading(false);
  }

  return (
    <Modal
      title={`Edição do contrato ${id}`}
      key={id}
      visible={visible}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      footer={null}
      closeIcon={<CloseCircleOutlined style={{ color: 'red' }} />}
      keyboard
      width={600}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleUpdateContract}
        style={{ textAlign: 'left', padding: 30, width: '100%' }}
        initialValues={{
          name,
          plan_id,
          crm_id,
          is_active,
          max_device,
          max_active,
          plan_type,
          day_debit,
          client_id,
        }}
      >
        <Form.Item
          label="Cliente"
          name="client_id"
          tooltip={{
            title: 'Selecione o cliente que o contrato está associado',
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: 'Informe a qual cliente este contrato será associado.',
            },
          ]}
        >
          <Select
            showSearch
            style={{ width: '50%' }}
            placeholder="Selecione o Cliente"
            optionFilterProp="children"
            disabled
            filterOption={
                (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}
          >
            {clientsList.map(
              (client) => (
                <Option key={client.id} value={client.id}>
                  {client.company_name}
                </Option>
              ),
            )}
          </Select>
        </Form.Item>

        <Form.Item label="Nome do Contrato" name="name">
          <Input />
        </Form.Item>

        <Form.Item
          label="Status do contrato"
          name="is_active"
          rules={[]}
        >
          <Select
            showSearch
            style={{ width: '50%' }}
            placeholder="Selecione o Cliente"
            optionFilterProp="children"
            filterOption={
                (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}
          >
            <Option key="activated-contract" value>Ativado</Option>
            <Option key="disabled-contract" value={false}>Desativado</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="CRM ID"
          name="crm_id"
          rules={[]}
        >
          <Input style={{ width: '50%' }} />
        </Form.Item>

        <Form.Item
          label="Max. dispositivos"
          name="max_device"
          tooltip={{
            title: 'Quantidade máxima de dispositivos',
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: 'Informe a quantidade máxima de dispositivos.',
            },
          ]}
        >
          <InputNumber style={{ width: '50%' }} min={1} max={100000} />
        </Form.Item>

        <Form.Item
          label="Max. dispositivos ativos"
          name="max_active"
          tooltip={{
            title: 'Quantidade máxima de dispositivos que podem ser ativados',
            icon: <InfoCircleOutlined />,
          }}
          rules={[]}
        >
          <InputNumber style={{ width: '50%' }} min={1} max={100000} />
        </Form.Item>

        <Form.Item
          label="Tipo do plano"
          name="plan_type"
          rules={[
            {
              required: false,
              message: 'Selecione um plano.',
            },
          ]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={
                  (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}
          >
            <Option key="diamond" value={1}>Diamond</Option>
            <Option key="premium" value={2}>Premium</Option>
            <Option key="gold" value={3}>Gold</Option>
            <Option key="standard" value={4}>Standard</Option>
            <Option key="starter" value={5}>Starter</Option>
          </Select>
        </Form.Item>

        {/* <Form.Item
          label="Dia de débito"
          name="day_debit"
          rules={[]}
        >
          <InputNumber style={{ width: '50%' }} min={1} max={28} />
        </Form.Item> */}

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '50%' }}>
            Salvar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

UpdateForm.propTypes = {
  contract_info: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default UpdateForm;
