import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

function TableFooter({
  className, entitySelected, entityName,
  onActivation, onDeactivation, onDelete,
}) {
  return (
    <footer
      className={className}
      style={{
        visibility: entitySelected.length > 0 ? 'visible' : 'hidden',
      }}
    >
      <div className="footer-text-left">
        <div>
          <p>
            Total de
            {' '}
            <strong>{entitySelected.length}</strong>
            {' '}
            {entityName}
            {' '}
            selecionados
          </p>
        </div>
      </div>
      <div className="footer-btns-right">
        <button
          type="button"
          onClick={onActivation}
        >
          <span>Ativar</span>
        </button>

        <button
          type="button"
          onClick={onDeactivation}
        >
          <span>Desativar</span>
        </button>

        <button
          type="button"
          onClick={onDelete}
        >
          <span>Deletar</span>
        </button>
      </div>

    </footer>
  );
}

TableFooter.propTypes = {
  entitySelected: PropTypes.func.isRequired,
  entityName: PropTypes.string.isRequired,
  className: PropTypes.func.isRequired,
  onDeactivation: PropTypes.func.isRequired,
  onActivation: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default TableFooter;
