import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import {
  PageHeader,
  Button,
  Descriptions,
  Layout,
  Tag,
  Card,
  Skeleton,
  Collapse,
} from 'antd';
import {
  LoginOutlined, SettingOutlined,
} from '@ant-design/icons';

import {
  AuthActions,
  ClientsActions,
  TagsActions,
  DevicesActions,
  MessagesActions,
} from '../../actions';
import ClientDevicesTable from './devicesTable';
import ProviderClients from './clientView';
import UpdatePasswordModal from './components/updatePassword';
import { AdvancedFilter } from '../../components';
import MessagesModal from '../../components/modal/messagesModal';
import './style.css';

function ClientView() {
  const dispatch = useDispatch();

  const { Content } = Layout;
  const { Panel } = Collapse;

  const { client, loading } = useSelector((state) => state.ClientsReducer);
  const { tagsList, loadMoreTags } = useSelector((state) => state.TagsReducer);
  const { u_type } = useSelector((state) => state.AuthReducer);
  const { openMessageModal } = useSelector((state) => state.DevicesReducer);
  const [updatePasswordModal, setUpdatePasswordModal] = useState(false);

  async function onSearch({ dev_eui }) {
    dispatch(DevicesActions.async_get_devices_by_filter({ dev_eui }));
  }

  async function onCleanFilters() {
    dispatch(DevicesActions.clean_search_result());
  }

  const renderContent = (column = 2) => (
    <Descriptions size="small" column={column}>
      <Descriptions.Item label="ID">
        {client.id}
      </Descriptions.Item>
      <Descriptions.Item label="E-mail">
        {client.email}
      </Descriptions.Item>
      <Descriptions.Item label="CNPJ/CPF">
        {client.cpf_cnpj}
      </Descriptions.Item>
      <Descriptions.Item label="Telefone">
        {client.phone}
      </Descriptions.Item>
    </Descriptions>
  );

  const PageHeaderContent = ({ children, extra }) => (
    <div className="content">
      <div className="main">{children}</div>
      <div className="extra">{extra}</div>
    </div>
  );

  useEffect(() => {
    if (!client.company_name) {
      dispatch(ClientsActions.async_get_one_client());
    }
  }, [dispatch, client, loading, u_type]);

  useEffect(() => {}, [openMessageModal]);

  useEffect(() => {
    if (tagsList.length < 1 && loadMoreTags) {
      dispatch(TagsActions.async_get_all_tags(100, 0));
      dispatch(TagsActions.load_more_tags(false));
    }
  }, [dispatch, u_type, loadMoreTags, tagsList]);

  return (
    <BrowserRouter>
      <div className="client-view-container">
        <Layout style={{ minHeight: '100vh' }}>
          <Card
            style={{ margin: 'auto', width: '90%', minHeight: '100vh' }}
            extra={[
              <Button
                key="logout-button"
                type="link"
                style={{ fontSize: '20px' }}
                danger
                onClick={() => dispatch(AuthActions.logout())}
              >
                <LoginOutlined />
                {' '}
                Sair
              </Button>,
            ]}
          >
            <Card>
              {client.company_name.length > 0
                ? (
                  <PageHeader
                    className="site-page-header-responsive"
                    title={`${client.company_name || ''}`}
                    tags={client.is_active
                      ? <Tag color="blue">Ativo</Tag>
                      : <Tag color="red">Desativo</Tag>}
                    extra={[
                      <Button
                        key="settings-button"
                        className="settings-button"
                        style={{ fontSize: '20px' }}
                        type="link"
                        onClick={() => setUpdatePasswordModal(true)}
                      >
                        <SettingOutlined />
                      </Button>,
                    ]}
                  >
                    <PageHeaderContent>
                      {renderContent()}
                    </PageHeaderContent>
                  </PageHeader>
                )
                : (<Skeleton active />)}
            </Card>
            <Card>
              <Collapse defaultActiveKey={['1']} ghost>
                <Panel header="Filtro de pesquisa" key="1">
                  <AdvancedFilter onSearch={onSearch} onCleanFilters={onCleanFilters} />
                </Panel>
              </Collapse>
            </Card>

            <Card key="device-table">
              <Content style={{ margin: 'auto' }}><ClientDevicesTable /></Content>
            </Card>

            {(u_type === 3 || u_type === '3') && (
              <Card key="clients-table">
                <ProviderClients />
              </Card>
            )}

            <MessagesModal
              onCleanFilters={() => dispatch(MessagesActions.clear_messages_search())}
              visible={openMessageModal}
              onCancel={() => dispatch(DevicesActions.open_message_modal(false))}
            />

            <UpdatePasswordModal
              visible={updatePasswordModal}
              onCancel={() => setUpdatePasswordModal(false)}
            />

          </Card>
        </Layout>
      </div>
    </BrowserRouter>
  );
}

export default ClientView;
