export const ACTION_TYPES = {
  SHOW_DEVICES: 'SHOW_DEVICES',
  DELETE_DEVICE: 'DELETE_DEVICE',
  ADD_DEVICE: 'ADD_DEVICE',
  UPDATE_DEVICE: 'UPDATE_DEVICE',
  LOAD_MORE_DEVICES: 'LOAD_MORE_DEVICES',
  CLEAR_DEVICES_LIST: 'CLEAR_DEVICES_LIST',
  LOADING_TABLE: 'LOADING_TABLE',
  LOAD_REQUEST: 'LOAD_REQUEST',
  SHOW_DEVICES_SEARCH_RESULT: 'SHOW_DEVICES_SEARCH_RESULT',
  CLEAN_SEARCH_RESULT: 'CLEAN_SEARCH_RESULT',
  UPDATE_DEVICE_TABLE_AFTER_SEARCH: 'UPDATE_DEVICE_TABLE_AFTER_SEARCH',
  OPEN_MESSAGE_MODAL: 'OPEN_MESSAGE_MODAL',

  // Ações assíncronas
  ASYNC_ADD_DEVICE: 'ASYNC_ADD_DEVICE',
  ASYNC_DELETE_DEVICE: 'ASYNC_DELETE_DEVICE',
  ASYNC_UPDATE_DEVICE: 'ASYNC_UPDATE_DEVICE',
  ASYNC_GET_DEVICES: 'ASYNC_GET_DEVICES',
  ASYNC_GET_DEVICES_AND_CONTRACTS: 'ASYNC_GET_DEVICES_AND_CONTRACTS',
  ASYNC_GET_ONE_DEVICE: 'ASYNC_GET_ONE_DEVICE',
  ASYNC_DEVICE_ACTIVATION: 'ASYNC_DEVICE_ACTIVATION',
  ASYNC_GET_DEVICES_BY_FILTERS: 'ASYNC_GET_DEVICES_BY_FILTERS',
  ASYNC_SEND_DOWNLINK_MESSAGE: 'ASYNC_SEND_DOWNLINK_MESSAGE',

  // ação para dashboard do cliente
  ASYNC_GET_CLIENT_DEVICES: 'ASYNC_GET_CLIENT_DEVICES',

  // Ações relacionadas à migrações
  ASYNC_MIGRATE_DEVICE: 'ASYNC_MIGRATE_DEVICE',
  CHANGE_TARGET_KEYS: 'CHANGE_TARGET_KEYS',
  ASYNC_LIST_MIGRATE_DEVICE: 'ASYNC_LIST_MIGRATE_DEVICE',
  LIST_MIGRATE_DEVICE: 'LIST_MIGRATE_DEVICE',
};

export const open_message_modal = (value) => ({
  type: ACTION_TYPES.OPEN_MESSAGE_MODAL,
  value,
});

export const update_device_table_after_search = (device_found) => ({
  type: ACTION_TYPES.UPDATE_DEVICE_TABLE_AFTER_SEARCH,
  device_found,
});

export const clean_search_result = () => ({
  type: ACTION_TYPES.CLEAN_SEARCH_RESULT,
});

export const show_devices_search_result = (search_result) => ({
  type: ACTION_TYPES.SHOW_DEVICES_SEARCH_RESULT,
  search_result,
});

export const set_loading_table = (value) => ({
  type: ACTION_TYPES.LOADING_TABLE,
  value,
});

export const load_request = (value) => ({
  type: ACTION_TYPES.LOAD_REQUEST,
  value,
});

export const update_device = (dev_eui, newData) => ({
  type: ACTION_TYPES.UPDATE_DEVICE,
  dev_eui,
  newData,
});

export const delete_device = (dev_eui) => ({
  type: ACTION_TYPES.DELETE_DEVICE,
  dev_eui,
});

export const add_device = (newDevice) => ({
  type: ACTION_TYPES.ADD_DEVICE,
  newDevice,
});

export const show_devices = (text) => ({
  type: ACTION_TYPES.SHOW_DEVICES,
  payload: text,
});

export const enable_load_more = (value) => ({
  type: ACTION_TYPES.LOAD_MORE_DEVICES,
  value,
});

export const clear_devices_list = () => ({
  type: ACTION_TYPES.CLEAR_DEVICES_LIST,
});

export const async_send_downlink_message = (dev_eui, payload, port, confirmed) => ({
  type: ACTION_TYPES.ASYNC_SEND_DOWNLINK_MESSAGE,
  dev_eui,
  payload,
  port,
  confirmed,
});

export const async_get_devices_by_filter = (filters) => ({
  type: ACTION_TYPES.ASYNC_GET_DEVICES_BY_FILTERS,
  filters,
});

export const async_get_all_devices = (limit = 100, offset = 0) => ({
  type: ACTION_TYPES.ASYNC_GET_DEVICES,
  limit,
  offset,
});

export const async_device_activation = (listOfDevices, activation) => ({
  type: ACTION_TYPES.ASYNC_DEVICE_ACTIVATION,
  data: listOfDevices,
  activation,
});

export const async_get_one_device = (text) => ({
  type: ACTION_TYPES.ASYNC_GET_ONE_DEVICE,
  payload: text,
});

export const async_add_device = (deviceData) => ({
  type: ACTION_TYPES.ASYNC_ADD_DEVICE,
  deviceData,
});

// Deleta um ou mais dipositivos
export const async_delete_device = (devices) => ({
  type: ACTION_TYPES.ASYNC_DELETE_DEVICE,
  devices,
});

export const async_update_device = (dev_eui, newData) => ({
  type: ACTION_TYPES.ASYNC_UPDATE_DEVICE,
  newData,
  dev_eui,
});

export const async_get_devices_and_contracts = (
  devices_limit = 100,
  devices_offset = 0,
  contracts_limit = 100,
  contracts_offset = 0,
) => ({
  type: ACTION_TYPES.ASYNC_GET_DEVICES_AND_CONTRACTS,
  devices_limit,
  devices_offset,
  contracts_limit,
  contracts_offset,
});

/*
  Retorna os dispositivos de apenas um cliente
*/
export const async_get_client_devices = () => ({
  type: ACTION_TYPES.ASYNC_GET_CLIENT_DEVICES,
});

/*
  ACTIONS DE MIGRAÇÃO DE DISPOSITIVOS
*/
export const async_migrate_device = ({ devices, contract_id, accessKey }) => ({
  type: ACTION_TYPES.ASYNC_MIGRATE_DEVICE,
  devices,
  contract_id,
  accessKey,
});

export const async_list_migrate_device = (payload) => ({
  type: ACTION_TYPES.ASYNC_LIST_MIGRATE_DEVICE,
  payload,
});

export const change_target_keys = (payload) => ({
  type: ACTION_TYPES.CHANGE_TARGET_KEYS,
  payload,
});

export const list_migrate_device = (devices) => ({
  type: ACTION_TYPES.LIST_MIGRATE_DEVICE,
  payload: devices,
});
