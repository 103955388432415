import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Form, Row, Col, Input, Space,
} from 'antd';

function AdvancedFilter({ onSearch, onCleanFilters }) {
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      style={{ width: '100%' }}
      onFinish={onSearch}
    >
      <Row>
        <Space align="baseline">
          <Col key="test">
            <Form.Item name="dev_eui" label="Dev EUI">
              <Input placeholder="abf3d46958131eca" maxLength="16" allowClear />
            </Form.Item>

          </Col>

          {/* <Col>
            <Form.Item name="app_eui" label="Application ID">
              <Input placeholder="d205a12e68a7e010" maxLength="16" allowClear />
            </Form.Item>
          </Col> */}

          {/* <Col>
            <Form.Item name="dev_addr" label="Device Address">
              <Input placeholder="b3a923fa" maxLength="8" allowClear />
            </Form.Item>
          </Col> */}

          {/* <Col>
            <Form.Item name="is_active" label="Status">
              <Input />
            </Form.Item>
          </Col> */}

          {/* <Col>
          <Form.Item name="tags" label="Tags">
            <Input placeholder="placeholder" />
          </Form.Item>
        </Col> */}

          <Col>
            <Button type="primary" htmlType="submit">
              Pesquisar
            </Button>

          </Col>

          <Col>
            <Button onClick={onCleanFilters}>
              Limpar
            </Button>
          </Col>
        </Space>

      </Row>
    </Form>
  );
}

AdvancedFilter.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onCleanFilters: PropTypes.func.isRequired,
};

export default AdvancedFilter;
