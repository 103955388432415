import { notification, message } from 'antd';
import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import api from '../../services/api';
import { DevicesActions, ContractActions } from '../../actions';

export function* getOneDevice(dev_eui) {
  const { data } = yield api.get(`/devices/${dev_eui}`).then((response) => response);
  data.key = `${data.id}`;

  return data;
}

export function* getDevicesByFilters({ filters }) {
  try {
    const { data } = yield api.get(`/devices/${filters.dev_eui}`)
      .then((response) => response);
    data.key = `${data.id}`;

    yield put(DevicesActions.show_devices_search_result(data));
    yield put(DevicesActions.update_device_table_after_search(data));
  } catch (error) {
    message.warning('Nenhum dispositivo foi encontrado com os filtros utilizados.');
  }
}

export function* addDevices({ deviceData }) {
  try {
    const { data } = yield api.post('/devices/create-device', deviceData).then((response) => response);
    notification.success({ message: 'Dispositivo registrado' });

    data.key = `${data.dev_eui}`;
    yield put(DevicesActions.add_device(data));
  } catch (error) {
    notification.error({
      message: 'Erro no cadastro do dispositivo',
      description: 'Ocorreu um erro na operação de cadastro do dispositivo.',
    });
  }
}

export function* deleteDevices({ devices }) {
  try {
    yield Promise.all(
      devices.map((dev_eui_id) => api
        .delete(`/devices/${dev_eui_id}`)
        .then(
          notification.success({ message: 'Dispositivo deletado com sucesso!' }),
        )),
    );
  } catch (error) {
    notification.error({
      message: 'Erro no delete!',
      description: 'Ocorreu um erro no processo. Verifique a conexão e tente novamente.',
    });
  }

  yield put(DevicesActions.delete_device(devices[0]));
}

export function* updateDevice({ dev_eui, newData }) {
  try {
    yield api.patch(`/devices/${dev_eui}`, newData);
    notification.success({ message: 'Dispositivo editado' });
    yield put(DevicesActions.load_request(false));
  } catch (error) {
    notification.error({
      message: 'Erro na edição do dispositivo',
      description: 'Ocorreu um erro na atualização dos dados. Revise os campos e tente novamente.',
    });
  }

  const deviceUpdated = yield getOneDevice(dev_eui);
  // console.log({ deviceUpdated });
  yield put(DevicesActions.update_device(dev_eui, deviceUpdated));
}

export function* getDevicesAndContracts({
  devices_limit,
  devices_offset,
  contracts_limit,
  contracts_offset,
}) {
  try {
    const data = yield Promise.all(
      [api.get('/contracts', { params: { limit: contracts_limit, offset: contracts_offset } }),
        api.get('/devices', { params: { limit: devices_limit, offset: devices_offset } })],
    )
      .then((responses) => {
        const contracts = responses[0].data;
        const devices = responses[1].data;

        return { contracts, devices };
      });

    const { contracts, devices } = data;

    if (contracts.length > 0) { contracts.map((i) => Object.assign(i, { key: `${i.id}` })); }
    if (devices.length > 0) { devices.map((i) => Object.assign(i, { key: `${i.id}` })); }

    const orderDevicesByID = devices.sort(
      (a, b) => {
        if (a.dev_eui > b.dev_eui) return 1;
        if (b.dev_eui > a.dev_eui) return -1;
        return 0;
      },
    );

    // atualiza a lista de dispositivos do devicesReducer
    yield put(DevicesActions.show_devices(orderDevicesByID));
    yield put(ContractActions.update_contracts_list(contracts));
  } catch (error) {
    notification.warning({
      message: 'Sessão expirou!',
      description: 'Faça o login novamente.',
    });
  }
}

export function* get_all_devices({ limit, offset }) {
  try {
    const { data } = yield api.get('/devices', { params: { limit, offset } })
      .then((response) => response);

    if (data.length > 0) { data.map((i) => Object.assign(i, { key: `${i.id}` })); }

    const orderDevicesByID = data.sort(
      (a, b) => {
        if (a.dev_eui > b.dev_eui) return 1;
        if (b.dev_eui > a.dev_eui) return -1;
        return 0;
      },
    );

    yield put(DevicesActions.set_loading_table(false));
    yield put(DevicesActions.show_devices(orderDevicesByID));
    yield put(DevicesActions.load_request(false));
  } catch (error) {
    notification.warning({
      message: 'Sessão expirou!',
      description: 'Faça o login novamente.',
    });

    // localStorage.removeItem('token');
    // localStorage.removeItem('user');

    yield put(push('/login'));
  }
}

export function* activateDevices({ data, activation }) {
  try {
    yield Promise.all(
      data.listOfDevices.map((dev_eui) => api
        .post(`/devices/${dev_eui}/activation`, { is_active: activation })
        .then((response) => {
          const { block_downlink } = response.data;
          if (!block_downlink) {
            notification.success({ message: `Dispositivo ${dev_eui} foi ativado.` });
          } else {
            notification.success({ message: `Dispositivo ${dev_eui} foi desativado.` });
          }
        })),
    );
    yield put(DevicesActions.load_request(false));
  } catch (error) {
    notification.error({ message: 'Erro no processo de ativação!' });
  }

  const { listOfDevices } = data;
  for (let i = 0; i < listOfDevices.length; i += 1) {
    const deviceUpdated = yield getOneDevice(listOfDevices[i]);
    yield put(DevicesActions.update_device(listOfDevices[i], deviceUpdated));
  }
}

// Retorna os dispositivos de apenas um cliente
export function* getClientDevices() {
  try {
    const devices = yield api.get('/devices')
      .then((response) => response.data);

    if (devices.length > 0) {
      devices.map((i) => Object.assign(i, { key: `${i.id}` }));
    }

    const orderDevicesByID = devices.sort(
      (a, b) => {
        if (a.dev_eui > b.dev_eui) return 1;
        if (b.dev_eui > a.dev_eui) return -1;
        return 0;
      },
    );

    // atualiza a lista de dispositivos do devicesReducer
    yield put(DevicesActions.show_devices(orderDevicesByID));
  } catch (warning) {
    notification.warning({ message: 'Sua sessão expirou!' });
    // localStorage.removeItem('token');
    // localStorage.removeItem('user');

    // yield put(AuthActions.login_success());
    yield put(push('/login'));
  }
}

export function* sendDownlinkMessage({
  dev_eui, payload, port, confirmed = true,
}) {
  try {
    yield api.post(`/messages/${dev_eui}/send-downlink-claim`, { payload, port, confirmed })
      .then(() => message.success('Mensagem enviada!'));
  } catch (error) {
    message.error(error.response.data.detail, 8);
  }
}

export function* migrateDevices({ accessKey, devices, contract_id }) {
  try {
    yield Promise.all(
      devices.map((dev_eui) => api
        .post(`/devices/${dev_eui}/migration`, { token: accessKey, contract_id })
        .then(() => {
          notification.success({ message: 'Dispositivos migrados' });
        })),
    );
  } catch (error) {
    notification.error({
      message: 'Erro no processo de migração',
      description: 'Ocorreu um erro no processo, verifique a conexão e tente novamente.',
    });
  }
}

export function* getMigrateDevices({ payload }) {
  try {
    const data = yield api.get(`/devices/by_token/${payload}`)
      .then((response) => {
        notification.success({ message: 'Chave válida' });
        return response.data;
      }).catch(() => {
        notification.error({ message: 'erro normal' });
        throw new Error({ message: 'Erro na busca' });
      });

    if (data.length > 0) {
      data.map((i) => Object.assign(i, { key: `${i.dev_eui}` }));
    }

    yield put(DevicesActions.list_migrate_device(data));
  } catch (error) {
    notification.error({
      message: 'Erro na busca',
      description: 'Chave inválida, tente outra chave de acesso',
    });
  }
}
