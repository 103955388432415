import React from 'react';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';

import './styles.css';

function LoagingDiv({ visibility }) {
  return (
    <div
      className="loading-div"
      style={{ visibility: visibility ? 'visible' : 'hidden' }}
    >
      <LoadingOutlined />
    </div>
  );
}

LoagingDiv.propTypes = {
  visibility: PropTypes.string.isRequired,
};

export default LoagingDiv;
