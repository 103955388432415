import { ACTION_TYPES } from '../../actions/connectionActions';

/**
 * @typedef {import('../../schemas/connections/index.js').ConnectionAndFilterSchema}
*/

const INITIAL_STATE = {
  /**
   * type {ConnectionAndFilterSchema[]}
   */
  connectionList: [],
  initialConnectionValues: {},
  totalConnections: 0,
  searchConnectionResult: [],
  loading: true,
  requestDevLoading: false,
  types: [],
  optionsList: [],
  devices: [],
  gateways: [],
  applications: [],
  loadingSearch: false,
  firstLoading: true,
};

const ConnectionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_FIRST_LOADING:
      return {
        ...state,
        firstLoading: action.value,
      };

    case ACTION_TYPES.LOADING_SEARCH:
      return {
        ...state,
        loadingSearch: action.loading,
      };

    case ACTION_TYPES.SET_DEVICES:
      return {
        ...state,
        devices: [...action.devices_list],
      };

    case ACTION_TYPES.SET_GATEWAYS:
      return {
        ...state,
        gateways: [...action.gateways_list],
      };

    case ACTION_TYPES.SET_APPLICATIONS:
      return {
        ...state,
        applications: [...action.applications_list],
      };

    case ACTION_TYPES.SET_OPTIONS:
      return {
        ...state,
        optionsList: [...action.options_list],
      };

    case ACTION_TYPES.SET_TYPES:
      return {
        ...state,
        types: [...action.types_list],
      };

    case ACTION_TYPES.SET_CONNECTION_VALUES:
      return {
        ...state,
        initialConnectionValues: { ...action.connection, ...action.filter },
      };

    case ACTION_TYPES.SAVE_CONNECTION_LIST:
      return {
        ...state,
        connectionList: [...action.connection_list],
        totalConnections: action.total,
      };

    case ACTION_TYPES.CLEAR_CONNECTION_LIST:
      return { ...state, connectionList: [] };

    case ACTION_TYPES.UPDATE_CONNECTION:
      return {
        ...state,
        connectionList: state.connectionList.map((connection) => {
          if (connection.ConnectionModel.id === action.id) {
            return {
              key: `${action.id}`,
              ConnectionModel: {
                id: action.updated_connection.id,
                auth_header: action.updated_connection.auth_header,
                url: action.updated_connection.url,
                client_id_label: action.updated_connection.client_id_label,
                topic_down: action.updated_connection.topic_down,
                topic_up: action.updated_connection.topic_up,
                uri: action.updated_connection.uri,
                connection_type: action.updated_connection.connection_type,
                description: action.updated_connection.description,
                created_at: action.updated_connection.created_at,
              },
              FilterModel: {
                key: `${action.updated_filter.id}`,
                id: action.updated_filter.id,
                applications: action.updated_filter.applications,
                description: action.updated_filter.description,
                devices: action.updated_filter.devices,
                duplicate: action.updated_filter.duplicate,
                gateways: action.updated_filter.gateways,
                lora: action.updated_filter.lora,
                radio: action.updated_filter.radio,
                tags: action.updated_filter.tags,
                types: action.updated_filter.types,
                with_tags: action.updated_filter.with_tags,
                is_disabled: action.updated_filter.is_disabled,
                created_at: action.updated_filter.created_at,
              },

            };
          }
          return connection;
        }),
      };

    case ACTION_TYPES.ADD_CONNECTION:
      return {
        ...state,
        connectionList: [...state.connectionList, action.new_connection],
        totalConnections: state.totalConnections + 1,
      };

    case ACTION_TYPES.DELETE_CONNECTION:
      return {
        ...state,
        connectionList: state.connectionList.filter(
          (connection) => connection.ConnectionModel.id !== action.id,
        ),
        totalConnections: state.totalConnections === 1 ? 0 : state.totalConnections - 1,
      };

      // case ACTION_TYPES.SHOW_DEVICES_SEARCH_RESULT:
      //   return {
      //     ...state,
      //     searchDevicesResult: [action.search_result],
      //   };

      // case ACTION_TYPES.CLEAN_SEARCH_RESULT:
      //   return {
      //     ...state,
      //     searchDevicesResult: [],
      //   };

    default:
      return state;
  }
};

export default ConnectionsReducer;
