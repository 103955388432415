import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Input, Form, Button, Select,
} from 'antd';
// import MaskedInput from 'antd-mask-input';

const UpdateForm = ({ values, providers, onFinish }) => {
  const { Option } = Select;
  const [form] = Form.useForm();

  const {
    company_name,
    email,
    cpf_cnpj,
    phone,
    c_type,
    is_active,
    key,
    provider_id,
  } = values;

  const cpfCnpjPattern = { cnpj: '11.111.111/1111-11', cpf: '111.111.111-11' };
  const parsedCpfCnpj = cpf_cnpj.replace(/\D/g, '');
  const [options, setOption] = useState(
    parsedCpfCnpj.length > 11 ? 'cnpj' : 'cpf',
  );

  const handleChangeOption = (value) => {
    setOption(value);
  };

  const selectBefore = (
    <Select defaultValue={options} onChange={handleChangeOption}>
      <Option value="cnpj">CNPJ</Option>
      <Option value="cpf">CPF</Option>
    </Select>
  );

  return (
    <>
      <Form
        form={form}
        key={`${key}form`}
        layout="vertical"
        hideRequiredMark
        onFinish={onFinish}
        initialValues={{
          company_name,
          email,
          cpf_cnpj: parsedCpfCnpj,
          phone,
          c_type,
          is_active,
          provider_id,
        }}
      >

        <Form.Item
          label="Nome"
          name="company_name"
          rules={[{
            required: true,
            message: 'Por favor, insira o nome！',
          }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[{
            required: true,
            message: 'Por favor, insira o email',
          }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Telefone"
          name="phone"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="CPF/CNPJ"
          name="cpf_cnpj"
          rules={[
            {
              required: true,
              message: 'Por favor, insira o CPF ou CNPJ',
            },
            {
              // pattern: /(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)/,
              whitespace: true,
              message: 'CPF ou CNPJ é inválido',
            },
          ]}
        >
          {/* <MaskedInput
            addonBefore={selectBefore}
            style={{ width: '100%' }}
            mask={options === 'cnpj' ? '11.111.111/1111-11' : '111.111.111-11'}
            placeholder={`Digite o seu ${options.toUpperCase()}`}
          /> */}
          <Input
            addonBefore={selectBefore}
            style={{ width: '100%' }}
            size="large"
            placeholder={`${cpfCnpjPattern[options]}`}
            maxLength={18}
          />
        </Form.Item>

        <Form.Item
          label="Fornecedor"
          name="provider_id"
        >
          <Select
            showSearch
            placeholder="Selecione o Cliente"
            optionFilterProp="children"
            filterOption={
                  (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}
          >
            {providers.filter((provider) => provider.c_type === 'provider')
              .map((provider) => (
                <Option key={provider.key} value={provider.id}>
                  {provider.company_name}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item shouldUpdate>
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: '100%' }}
              disabled={
                form.getFieldsError().filter(({ errors }) => errors.length)
                  .length
              }
            >
              Salvar
            </Button>
          )}
        </Form.Item>
      </Form>
    </>
  );
};

UpdateForm.propTypes = {
  values: PropTypes.func.isRequired,
  providers: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default UpdateForm;
