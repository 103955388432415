import { message } from 'antd';
import { put } from 'redux-saga/effects';

import api from '../../services/api';
import { ConnectionsActions } from '../../actions';

function messageErrorHandler({ status, statusText, data }) {
  let error_detail = '';

  if (status === 401) {
    message.warning('Sua sessção expirou! Realize o login novamente.', 3);
    return;
  } if (data.detail) {
    const { detail } = data;

    if (typeof (detail) === 'string') {
      //
      error_detail = detail;
    } else if (typeof (detail) === 'object') {
      // Erros de validação da api lora
      const error_from_api = Object.keys(detail).includes('errors');

      if (error_from_api) {
        // Erros de validação dos campos retornados da Everynet
        const fields = Object.keys(detail.errors);
        error_detail = `O(s) campo(s): ${fields}, contém valor(es) inválido(s).`;
      } else {
        // Erros de validação no schema retornados pela api lora
        error_detail = `${detail[0].msg}. Problema no(s) campo(s): ${detail[0].loc.at(-1)}`;
      }
    }
  } else {
    message.error(`Erro. Detalhe: ${statusText}`, 8);
  }

  message.error(`Erro na ọperação. Detalhe: ${error_detail}`, 8);
}

export function* getAllConnections({
  limit, offset, connection_type, client_id,
}) {
  try {
    const result = yield api.get('/connections', {
      params: {
        limit, offset, connection_type, client_id,
      },
    })
      .then((response) => response.data);

    yield put(ConnectionsActions.set_loading_search(false));
    yield put(ConnectionsActions.set_first_loading(false));

    const { data, total } = result;

    if (data.length === 0 && (client_id || connection_type)) {
      message.warning('Nenhuma conexão foi encontrada');
    } else {
      yield put(ConnectionsActions.save_connection_list(data, total));
    }
  } catch (error) {
    messageErrorHandler(error.response);
  }
}

export function* createConnection({ connections, filters, client_id }) {
  const hide = message.loading('Processando registro...', 0);

  try {
    const { data } = yield api.post('/connections', { connections, filters, client_id })
      .then((response) => response);

    const new_data = {
      ConnectionModel: { ...data.connections },
      FilterModel: { ...data.filters },
    };

    setTimeout(hide, 0.5);
    yield put(ConnectionsActions.add_connection(new_data));
    message.success('Conexão criada');
  } catch (error) {
    setTimeout(hide, 0.5);
    messageErrorHandler(error.response);
  }
}

export function* updateConnection({
  id, updated_connection, updated_filter,
}) {
  const hide = message.loading('atualizando...', 0);
  try {
    const { connections, filters } = yield api.patch(`/connections/${id}`, {
      connections: updated_connection,
      filters: updated_filter,
    }).then((response) => response.data);

    yield put(ConnectionsActions.update_connection(id, connections, filters));

    setTimeout(hide, 0.5);
    message.success('Conexão atualizada');
  } catch (error) {
    setTimeout(hide, 0.5);
    messageErrorHandler(error.response);
  }
}

export function* deleteConnection({ id }) {
  const hide = message.loading('Deletando...', 0);
  try {
    yield api.delete(`/connections/${id}`).then((response) => response);

    setTimeout(hide, 0.5);
    yield put(ConnectionsActions.delete_connection(id));
    message.success('Conexão deletada');
  } catch (error) {
    setTimeout(hide, 0.5);
    messageErrorHandler(error.response);
  }
}
