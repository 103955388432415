import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Highlighter from 'react-highlight-words';
import {
  Table,
  Button,
  Space,
  Input,
  Tag,
  Card,
} from 'antd';
import {
  SearchOutlined,
} from '@ant-design/icons';

import { ClientsActions } from '../../actions';

function ProviderClients() {
  const dispatch = useDispatch();
  const { clientsList, loadMoreClients } = useSelector((state) => state.ClientsReducer);

  const [params, setParams] = useState({ limit: 50, offset: 0 });
  const { limit, offset } = params;
  const [state, setState] = useState({
    searchText: '',
    searchedColumn: '',
    visible: false,
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: '' });
  };

  const getFilterClients = (dataIndex) => ({
    // neste caso são aplicadas na pesquisa de um item
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      clearFilters,
      confirm,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Procurar
          </Button>

          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Cancelar
          </Button>
        </Space>
      </div>
    ),

    filterIcon: () => <SearchOutlined style={{ color: '#1890ff' }} />,

    onFilter: (value, record) => (record[dataIndex]
      ? record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
      : ''),

    render: (text) => (state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[state.searchText]}
        autoEscape
        // eslint-disable-next-line react/destructuring-assignment
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )),
  });

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'company_name',
      key: 'company_name',
      width: 140,
      fixed: 'left',
      ...getFilterClients('company_name'),
    },

    {
      title: 'Cnpj',
      dataIndex: 'cpf_cnpj',
      key: 'cpf_cnpj',
      width: 90,
      ...getFilterClients('cpf_cnpj'),
    },

    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 150,
      ...getFilterClients('email'),
      render: (email) => <a href={`mailto:${email}`}>{email}</a>,
    },

    {
      title: 'Telefone',
      dataIndex: 'phone',
      key: 'phone',
      width: 90,
      ...getFilterClients('phone'),
    },

    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      width: 100,
      render: (is_active) => (
        <>
          {is_active ? (
            <Tag color="green">Ativado</Tag>
          ) : (
            <Tag color="red">Desativado</Tag>
          )}
        </>
      ),
    },
  ];

  // console.log(clientsList);
  useEffect(() => {
    if (loadMoreClients) {
      // dispatch(ClientsActions.async_get_clients(limit, offset));
      dispatch(ClientsActions.async_get_providers_client(limit, offset));
      setParams({ limit: 50, offset: offset + limit });
      dispatch(ClientsActions.enable_load_more_clients(false));
    }
  }, [dispatch, params, clientsList, loadMoreClients]);

  return (
    <Card
      title={`Total de ${clientsList.length} clientes associados`}
      bordered={false}
      style={{ width: '100%' }}
      key="odfndoio"
    >
      <Table
        columns={columns}
        dataSource={clientsList}
        pagination={{ pageSize: 10, position: ['bottomCenter'] }}
        scroll={{ x: 1000 }}
        bordered
        footer={() => (
          <>
            <Button
              type="link"
              onClick={() => dispatch(ClientsActions.enable_load_more_clients(true))}
            >
              Carregar mais
            </Button>
          </>
        )}
      />
    </Card>
  );
}

export default ProviderClients;
