import React from 'react';

import ClientView from '../../ClientView';
import Connections from '../../Connections';

const SubRoutes = [
  {
    path: '/home-view',
    exact: true,
    component: () => <ClientView />,
    key: 'home-view',
  },
  {
    path: '/home-view/connections',
    component: () => <Connections />,
    key: 'connections',
  },

];

export default SubRoutes;
