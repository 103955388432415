import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import {
  Table,
  Modal,
  Typography,
  Button,
  Space,
  Input,
  Tag,
  Menu,
  Dropdown,
} from 'antd';
import {
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
  MoreOutlined,
  CloseCircleOutlined,
  // LoadingOutlined,
} from '@ant-design/icons';

import './style.css';
import { ClientsActions } from '../../../actions';
import UpdateForm from './updateForm';
// import ExpendableRow from './expendableRow';

function ClientsManagement() {
  const { Title } = Typography;
  const dispatch = useDispatch();

  const { clientsList, loadMoreClients } = useSelector((state) => state.ClientsReducer);
  // const { usersList } = useSelector((state) => state.UsersReducers);
  const { collapsed } = useSelector((state) => state.SiderReducer);

  const [newClass, setNewClass] = useState('');
  const [state, setState] = useState({
    searchText: '',
    searchedColumn: '',
    visible: false,
  });
  const [clientsSelected, setClientsSelected] = useState([]);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [activeClients, setActiveClients] = useState(0);
  const [params, setParams] = useState({ limit: 100, offset: 0 });
  const { limit, offset } = params;

  async function handleRemoveClient(clientArraySelected, name, id) {
    const listOfClients = clientArraySelected.length < 1
      ? [id]
      : clientArraySelected.map((client) => client.id);
    Modal.confirm({
      title: `Tem certeza que deseja deletar o cliente ${name} ?`,
      icon: <ExclamationCircleOutlined />,

      async onOk() {
        dispatch(ClientsActions.async_delete_client(listOfClients));
      },
    });
  }

  async function activation(arrayOfClients, id, is_active) {
    dispatch(
      ClientsActions.async_client_activation(
        {
          listOfClients:
            arrayOfClients.length < 1
              ? [id]
              : arrayOfClients.map((client) => client.id),
        },
        is_active,
      ),
    );
  }

  const rowSelection = {
    onChange: (_, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setClientsSelected(selectedRows);
    },
  };

  // Requisição de edição das informações do cliente
  async function handleFinish({
    company_name,
    email,
    cpf_cnpj,
    phone,
    provider_id = 0,
  }) {
    setConfirmLoading(true);
    const { id, is_active, c_type } = formValues;

    dispatch(
      ClientsActions.async_update_client(id, {
        company_name,
        email,
        phone,
        cpf_cnpj,
        provider_id,
        c_type,
        is_active,
      }),
    );

    setConfirmLoading(false);
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: '' });
  };

  const getFilterClients = (dataIndex) => ({
    // neste caso são aplicadas na pesquisa de um item
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      clearFilters,
      confirm,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={(_) => {
          // searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Procurar
          </Button>

          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Cancelar
          </Button>
        </Space>
      </div>
    ),

    filterIcon: () => <SearchOutlined style={{ color: '#1890ff' }} />,

    onFilter: (value, record) => (record[dataIndex]
      ? record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
      : ''),

    render: (text) => (state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[state.searchText]}
        autoEscape
        // eslint-disable-next-line react/destructuring-assignment
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )),
  });

  // menu contem as opções para cada row da tabela
  const menu = ({ company_name, id, is_active }) => (
    <Menu>
      {!is_active ? (
        <Menu.Item
          key="activate-button-menu"
          onClick={() => activation([formValues], id, !is_active)}
        >
          Ativar
        </Menu.Item>
      ) : (
        <Menu.Item
          key="deactivate-button-menu"
          onClick={() => activation(clientsSelected, id, false)}
        >
          Desativar
        </Menu.Item>
      )}

      <Menu.Item
        key="delete-button-menu"
        onClick={() => handleRemoveClient(clientsSelected, company_name, id)}
      >
        Deletar
      </Menu.Item>

      <Menu.Item key="edit-button-menu" onClick={() => setVisible(true)}>
        Editar
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'company_name',
      key: 'company_name',
      width: 140,
      fixed: 'left',
      ...getFilterClients('company_name'),
    },

    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 70,
      ...getFilterClients('id'),
    },

    {
      title: 'Cnpj',
      dataIndex: 'cpf_cnpj',
      key: 'cpf_cnpj',
      width: 90,
      ...getFilterClients('cpf_cnpj'),
    },

    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 150,
      ...getFilterClients('email'),
      render: (email) => <a href={`mailto:${email}`}>{email}</a>,
    },

    {
      title: 'Telefone',
      dataIndex: 'phone',
      key: 'phone',
      width: 90,
      ...getFilterClients('phone'),
    },

    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      width: 100,
      render: (is_active) => (
        <>
          {is_active ? (
            <Tag color="green">Ativado</Tag>
          ) : (
            <Tag color="red">Desativado</Tag>
          )}
        </>
      ),
    },
    {
      title: 'Categoria',
      dataIndex: 'c_type',
      key: 'c_type',
      width: 120,
      ...getFilterClients('c_type'),
      render: (...record) => (
        <div className="actions">
          {record[1].c_type}

          <Dropdown
            overlay={menu(record[1])}
            trigger={['click']}
            onClick={() => setFormValues(record[1])}
          >
            <MoreOutlined style={{ color: '#1890ff', fontSize: '25px' }} />
          </Dropdown>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (loadMoreClients) {
      setParams({ limit: 100, offset: offset + limit });
      dispatch(ClientsActions.async_get_clients(limit, offset));
      dispatch(ClientsActions.enable_load_more_clients(false));
    }

    const clients = clientsList.filter((client) => client.is_active);
    setActiveClients(clients.length);

    if (!collapsed) {
      setNewClass('footer-opened');
    } else {
      setNewClass('footer-closed');
    }
  }, [clientsList, formValues, dispatch, collapsed, newClass, params]);

  return (
    <div className="client-container">
      <div className="header-container">
        <Title>Gerenciamento de Clientes</Title>

        <Button type="primary" key="add-client-button">
          <Link to="/client-register">
            Novo Cliente
            {' '}
            <PlusOutlined />
          </Link>
        </Button>
      </div>

      <div className="actions">
        <h3>
          <strong>
            Total
            {' '}
            {activeClients}
            {' '}
            de clientes ativos
          </strong>
        </h3>
      </div>

      <Table
        columns={columns}
        dataSource={clientsList}
        pagination={{ pageSize: 20, position: ['bottomCenter'] }}
        scroll={{ x: '80%' }}
        bordered
        loading={!(clientsList.length > 0)}
        rowSelection={rowSelection}
        footer={() => (
          <>
            <Button
              type="link"
              onClick={() => dispatch(ClientsActions.enable_load_more_clients(true))}
            >
              <PlusOutlined />
              Carregar mais
            </Button>
          </>
        )}
      />

      <footer
        className={newClass}
        style={{
          visibility: clientsSelected.length > 0 ? 'visible' : 'hidden',
        }}
      >
        <div className="footer-text-left">
          <div>
            <p>
              Total de
              {' '}
              <strong>{clientsSelected.length}</strong>
              {' '}
              clientes
              selecionados
            </p>
          </div>
        </div>

        <div className="footer-btns-right">
          <button
            type="button"
            onClick={() => activation(clientsSelected, null, true)}
          >
            <span>Ativar</span>
          </button>

          <button
            type="button"
            onClick={() => activation(clientsSelected, null, false)}
          >
            <span>Desativar</span>
          </button>
        </div>
      </footer>

      <Modal
        title={`Edição do cliente ${formValues.company_name}`}
        key={`${formValues.key}form-update`}
        visible={visible}
        onOk={() => setVisible(false)}
        confirmLoading={confirmLoading}
        onCancel={() => setVisible(false)}
        closeIcon={<CloseCircleOutlined style={{ color: 'red' }} />}
        footer={null}
        width={430}
      >
        <UpdateForm
          values={formValues}
          onFinish={handleFinish}
          providers={clientsList}
        />
      </Modal>

    </div>
  );
}

export default ClientsManagement;
