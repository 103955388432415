import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Highlighter from 'react-highlight-words';
import {
  Table,
  Modal,
  Typography,
  Button,
  Space,
  Input,
  Tag,
  Menu,
  Dropdown,
} from 'antd';
import {
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
  MoreOutlined,
} from '@ant-design/icons';

import './style.css';
import {
  CreateForm,
  UpdateForm,
} from './subComponents';
import { ContractActions, ClientsActions } from '../../actions';

function Contracts() {
  const { Title } = Typography;
  const dispatch = useDispatch();
  const { contractsList, loadMore } = useSelector((state) => state.ContractsReducer);
  const { clientsList } = useSelector((state) => state.ClientsReducer);
  const { collapsed } = useSelector((state) => state.SiderReducer);

  const [newClass, setNewClass] = useState('');
  const [state, setState] = useState({
    searchText: '',
    searchedColumn: '',
    visible: false,
  });
  const [contractsSelected, setContractsSelected] = useState([]);
  const [updateFormVisibility, setUpdateFormVisibility] = useState(false);
  const [createModalVisibility, setcreateModalVisibility] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [activeContracts, setActiveContracts] = useState(0);
  const [params, setParams] = useState({ limit: 100, offset: 0 });

  const { limit, offset } = params;

  const rowSelection = {
    onChange: (_, selectedRows) => {
      setContractsSelected(selectedRows);
    },
  };

  async function handleDeleteContract(contractsArraySelected, id) {
    const listOfContracts = contractsArraySelected.length < 1
      ? [id]
      : contractsArraySelected.map((client) => client.id);
    Modal.confirm({
      title: id ? `Tem certeza que deseja deletar o(s) contrato(s) ${id} ?`
        : 'Tem certeza que deseja deletar o(s) contrato(s)',
      icon: <ExclamationCircleOutlined />,

      async onOk() {
        dispatch(ContractActions.async_delete_contract(listOfContracts));
      },
    });
  }

  // async function activateContract(contact_id, activationStatus) {
  //   console.log({ contact_id, activationStatus });

  //   dispatch(
  //     ContractActions.activate_contracts({
  //       contractsIdList:
  //         contractsSelected.length < 1
  //           ? [contact_id]
  //           : contractsSelected.map((contract) => contract.id),
  //     },
  //     activationStatus),
  //   );
  // }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: '' });
  };

  const getFilterClients = (dataIndex) => ({
    // neste caso são aplicadas na pesquisa de um item
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      clearFilters,
      confirm,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Procurar
          </Button>

          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Cancelar
          </Button>
        </Space>
      </div>
    ),

    filterIcon: () => <SearchOutlined style={{ color: '#1890ff' }} />,

    onFilter: (value, record) => (record[dataIndex]
      ? record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
      : ''),

    render: (text) => (state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[state.searchText]}
        autoEscape
        // eslint-disable-next-line react/destructuring-assignment
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )),
  });

  const tableRowsOptions = ({ id }) => (
    <Menu>
      {/* {!is_active ? (
        <Menu.Item
          key="activate-button-menu"
          onClick={() => activateContract(id, true)}
        >
          Ativar
        </Menu.Item>
      ) : (
        <Menu.Item
          key="deactivate-button-menu"
          onClick={() => activateContract(id, false)}
        >
          Desativar
        </Menu.Item>
      )} */}

      <Menu.Item
        key="delete-button-menu"
        onClick={() => handleDeleteContract(contractsSelected, id)}
      >
        Deletar
      </Menu.Item>

      <Menu.Item key="edit-button-menu" onClick={() => setUpdateFormVisibility(true)}>
        Editar
      </Menu.Item>
    </Menu>
  );

  const getClientNameById = (client_id) => {
    const { company_name } = clientsList.filter((client) => client_id === client.id)[0] || 'Sem cliente associado';
    return company_name || 'Sem cliente associado';
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 70,
      fixed: 'left',
      ...getFilterClients('id'),
    },

    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      width: 70,
      fixed: 'left',
      ...getFilterClients('id'),
    },

    {
      title: 'Cliente',
      dataIndex: 'client_id',
      key: 'company_name',
      width: 70,
      render: (client_id) => getClientNameById(client_id),
    },

    {
      title: 'ID do plano',
      dataIndex: 'plan_id',
      key: 'plan_id',
      width: 70,
      ...getFilterClients('plan_id'),
    },

    {
      title: 'Tipo do plano',
      dataIndex: 'plan_type',
      key: 'plan_type',
      width: 70,
      ...getFilterClients('plan_type'),
    },

    {
      title: 'Max. dev.',
      dataIndex: 'max_device',
      key: 'max_device',
      width: 90,
      ...getFilterClients('max_device'),
    },

    {
      title: 'Max. dev. ativos',
      dataIndex: 'max_active',
      key: 'max_active',
      width: 90,
      ...getFilterClients('max_active'),
    },

    {
      title: 'Dispositivos registrados',
      dataIndex: 'counter_device',
      key: 'counter_device',
      width: 100,
      ...getFilterClients('counter_device'),
      render: (counter_device) => counter_device || 0,
    },

    {
      title: 'Dispositivos ativos',
      dataIndex: 'counter_active',
      key: 'counter_active',
      width: 90,
      ...getFilterClients('counter_active'),
      render: (counter_active) => counter_active || 0,
    },

    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      width: 100,
      render: (...record) => (
        <div className="actions">
          {record[1].is_active ? (
            <Tag color="green">Ativado</Tag>
          ) : (
            <Tag color="red">Desativado</Tag>
          )}

          <Dropdown
            overlay={tableRowsOptions(record[1])}
            trigger={['click']}
            onClick={() => setFormValues(record[1])}
          >
            <MoreOutlined style={{ color: '#1890ff', fontSize: '25px' }} />
          </Dropdown>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (loadMore) {
      setParams({ limit: 100, offset: offset + limit });
      dispatch(ContractActions.async_get_all_contracts(limit, offset));
      dispatch(ContractActions.enable_load_more(false));
    }

    if (clientsList.length < 1) {
      dispatch(ClientsActions.async_get_clients(100, 0));
      dispatch(ClientsActions.enable_load_more_clients(false));
    }

    const clients = contractsList.filter((client) => client.is_active);
    setActiveContracts(clients.length);

    if (!collapsed) {
      setNewClass('footer-opened');
    } else {
      setNewClass('footer-closed');
    }
  }, [contractsList, formValues, dispatch, collapsed,
    newClass, updateFormVisibility, params, clientsList,
    loadMore]);

  return (
    <div className="client-container">
      <div className="header-container">
        <Title>Gerenciamento de Contratos</Title>

        <Button
          type="primary"
          key="add-client-button"
          onClick={() => setcreateModalVisibility(true)}
        >
          Novo Contrato
          {' '}
          <PlusOutlined />
        </Button>

      </div>

      <div className="actions">
        <h3>
          <strong>
            Total
            {' '}
            {activeContracts}
            {' '}
            de contratos ativos
          </strong>
        </h3>
      </div>

      <Table
        columns={columns}
        dataSource={contractsList}
        pagination={{ pageSize: 10, position: ['bottomCenter'] }}
        scroll={{ x: '80%' }}
        bordered
        loading={!(contractsList.length > 0)}
        rowSelection={rowSelection}
        footer={() => (
          <>
            <Button
              type="link"
              onClick={() => dispatch(ContractActions.enable_load_more(true))}
            >
              <PlusOutlined />
              Carregar mais
            </Button>
          </>
        )}
      />

      <footer
        className={newClass}
        style={{
          visibility: contractsSelected.length > 0 ? 'visible' : 'hidden',
        }}
      >
        <div className="footer-text-left">
          <div>
            <p>
              Total de
              {' '}
              <strong>{contractsSelected.length}</strong>
              {' '}
              contratos
              selecionados
            </p>
          </div>
        </div>

        <div className="footer-btns-right">
          <button
            type="button"
            onClick={() => handleDeleteContract(contractsSelected, null)}
          >
            <span>Deletar</span>
          </button>
        </div>
      </footer>

      <UpdateForm
        key={formValues.id}
        contract_info={formValues}
        visible={updateFormVisibility}
        onCancel={() => setUpdateFormVisibility(false)}
      />

      <CreateForm
        visible={createModalVisibility}
        onCancel={() => setcreateModalVisibility(false)}
      />
    </div>
  );
}

export default Contracts;
