export const ACTION_TYPES = {
  SAVE_MESSAGES: 'SAVE_MESSAGES',
  CLEAR_MESSAGES_SEARCH: 'CLEAR_MESSAGES_SEARCH',

  ASYNC_GET_MESSAGES: 'ASYNC_GET_MESSAGES',
};

export const save_messages = (messages) => ({
  type: ACTION_TYPES.SAVE_MESSAGES,
  messages,
});

export const clear_messages_search = () => ({
  type: ACTION_TYPES.CLEAR_MESSAGES_SEARCH,
});

export const async_get_messages = (dev_eui, filters) => ({
  type: ACTION_TYPES.ASYNC_GET_MESSAGES,
  dev_eui,
  filters,
});
