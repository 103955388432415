import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import {
  Button,
  Tag,
  Modal,
  // Typography,
  Collapse,
  Layout,
  Table,
  Space,
  Input,
  Menu,
  Dropdown,
  Tooltip,
  Card,
  PageHeader,
  Statistic,
} from 'antd';
import {
  SearchOutlined,
  ExclamationCircleOutlined,
  MoreOutlined,
  PlusOutlined,
  // ReloadOutlined,
  EditOutlined,
  SendOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
  FallOutlined,
  RiseOutlined,
} from '@ant-design/icons';

import UpdateDeviceForm from './updateForm';
import { DevicesActions } from '../../../actions';
import {
  TableFooter,
  LoadingDiv,
  AdvancedFilter,
  DownlinkMessageModal,
} from '../../../components';
import './style.css';

function DevicesManagement() {
  // const { Title } = Typography;
  const dispatch = useDispatch();
  const { Content } = Layout;
  const { Panel } = Collapse;

  const { clientsList } = useSelector((state) => state.ClientsReducer);
  const {
    devicesList,
    loadMoreDevices,
    searchDevicesResult,
    requestDevLoading,
  } = useSelector((state) => state.DevicesReducer);
  const { collapsed } = useSelector((state) => state.SiderReducer);

  const [modalsVisibility, setModalsVisibility] = useState({
    connection_modal: false,
    filter_modal: false,
    update_device_modal: false,
    downlink_message_modal: false,
  });
  const [newClass, setNewClass] = useState('');
  const [state, setState] = useState({ searchText: '', searchedColumn: '' });
  const [devicesSelected, setDevicesSelected] = useState([]);
  const [formValues, setFormValues] = useState({}); // guarda as informações do device selecionado
  const [visible, setVisible] = useState(false);
  const [activeDevices, setActiveDevices] = useState(0);
  const [params, setParams] = useState({ limit: 100, offset: 0 });

  const { limit, offset } = params;

  async function onSearch({ dev_eui }) {
    dispatch(DevicesActions.async_get_devices_by_filter({ dev_eui }));
  }

  async function onCleanFilters() {
    dispatch(DevicesActions.clean_search_result());
  }

  function handleActivation(arrayOfDevices, dev_eui, activation) {
    Modal.confirm({
      title: `Confirma a ${activation === 'True' ? 'ativação' : 'desativação'} do dispositivo?`,
      icon: <ExclamationCircleOutlined />,
      content: activation === 'True'
        ? 'A mudança do estado do dispositivo pode gerar cobranças!'
        : '',

      async onOk() {
        await dispatch(DevicesActions.load_request(true));
        dispatch(DevicesActions.async_device_activation({
          listOfDevices: arrayOfDevices.length < 0
            ? [dev_eui] : arrayOfDevices.map((device) => device.dev_eui),
        }, activation));
      },
    });
  }

  function handleRemoveDevice(arrayOfDevicesSelected, dev_eui_id) {
    const listOfDevices = arrayOfDevicesSelected.length < 1
      ? [dev_eui_id] : arrayOfDevicesSelected.map((device) => device.dev_eui);

    const title_message = listOfDevices.length < 2
      ? `Tem certeza que deseja deletar o dispositivo ${dev_eui_id || listOfDevices}?`
      : 'Tem certeza que deseja deletar os dispositivos';

    Modal.confirm({
      title: title_message,
      icon: <ExclamationCircleOutlined />,
      content: '',

      onOk() {
        dispatch(DevicesActions.async_delete_device(listOfDevices));
        // console.log(listOfDevices);
      },
    });
  }

  async function handleUpdateDevice({
    contract_id,
    tags,
    app_eui,
    dev_class,
    encryption,
    activation,
    counters_size,
    nwkskey,
    dev_addr,
    appskey,
    app_key,
    strict_counter,
    band,
    mode,
  }) {
    const { dev_eui } = formValues;

    dispatch(DevicesActions.async_update_device(dev_eui, {
      contract_id,
      tags,
      app_eui,
      dev_class,
      encryption,
      activation,
      counters_size,
      nwkskey,
      dev_addr,
      appskey,
      app_key,
      strict_counter,
      band,
      adr: { mode },
    }));
    setVisible(false);
  }

  async function handleLoadMoreDevices() {
    dispatch(DevicesActions.load_request(true));
    setParams({ limit: 100, offset: offset + limit });
    await dispatch(DevicesActions.async_get_all_devices(limit, offset));
  }

  const rowSelection = {
    onChange: (_, selectedRows) => {
      setDevicesSelected(selectedRows);
    },
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: '' });
  };

  const getFilterDevices = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, clearFilters, confirm,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Procurar
          </Button>

          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </Space>
      </div>
    ),

    filterIcon: () => <SearchOutlined style={{ color: '#1890ff' }} />,

    onFilter: (value, record) => (record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : ''),

    render: (text) => (state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[state.searchText]}
        autoEscape
        // eslint-disable-next-line react/destructuring-assignment
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )),
  });

  const menu = ({ dev_eui, block_downlink }) => (
    <Menu>
      {block_downlink
        ? (
          <Menu.Item
            key="desativate-device"
            onClick={() => handleActivation([formValues], dev_eui, 'True')}
          >
            <RiseOutlined />
            {' '}
            Ativar
          </Menu.Item>
        )
        : (
          <Menu.Item
            key="activate-device"
            onClick={() => handleActivation([formValues], dev_eui, 'False')}
          >
            <FallOutlined />
            {' '}
            Desativar
          </Menu.Item>
        )}

      <Menu.Item
        key="delete-device"
        onClick={() => handleRemoveDevice([formValues], dev_eui)}
      >
        <DeleteOutlined style={{ color: 'red' }} />
        {' '}
        Deletar
      </Menu.Item>

      <Menu.Item onClick={() => setModalsVisibility({ downlink_message_modal: true })}>
        <SendOutlined style={{ color: 'green' }} />
        {' '}
        Enviar downlink
      </Menu.Item>

      <Menu.Item key="edit-device" onClick={() => setVisible(true)}>
        <EditOutlined style={{ color: 'blue' }} />
        {' '}
        Editar
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: 'Device EUI',
      dataIndex: 'dev_eui',
      key: 'dev_eui',
      width: 90,
      // fixed: 'left',
      ...getFilterDevices('dev_eui'),
    },

    {
      title: 'Application ID',
      dataIndex: 'app_eui',
      key: 'app_eui',
      width: 80,
      ...getFilterDevices('app_eui'),
    },

    {
      title: 'Contrato',
      dataIndex: 'contract_id',
      key: 'contract_id',
      width: 70,
      ...getFilterDevices('contract_id'),
      render: (contract_id) => (
        <>
          {contract_id || 'Nenhum contrato associado'}
        </>
      ),
    },

    {
      title: 'Status',
      dataIndex: 'block_downlink',
      key: 'status',
      width: 80,
      render: (block_downlink) => (
        <>
          {!block_downlink
            ? <Tag color="green">Ativado</Tag>
            : <Tag color="red">Desativado</Tag>}
        </>
      ),
    },

    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      width: 100,
      ...getFilterDevices('tags'),
      render: (tags) => (
        <>
          {tags
            // eslint-disable-next-line react/destructuring-assignment
            ? tags.map((tag) => (
              <Tag color="blue">{tag.replace(/["]/g, '')}</Tag>
            ))
            : <Tag color="default">{'Sem tags'.replace(/["]/g, '')}</Tag>}
        </>
      ),
    },

    {
      title: 'Modo ADR',
      dataIndex: 'adr',
      key: 'adr',
      width: 70,
      render: ({ mode }) => (
        <>
          {mode === 'on'
            ? <Tag color="green">{'Ativado'.replace(/["]/g, '')}</Tag>
            : <Tag color="red">{'Desativado'.replace(/["]/g, '')}</Tag>}

        </>
      ),
    },

    {
      title: 'Downlinks',
      dataIndex: 'counter_down',
      key: 'counter_down',
      width: 70,
      render: (counter_down) => counter_down || 0,
    },

    {
      title: 'Uplinks',
      dataIndex: 'counter_up',
      key: 'counter_up',
      width: 70,
      render: (counter_up) => counter_up || 0,
    },

    {
      title: 'Data de ativação',
      dataIndex: 'last_activity',
      key: 'last_activity',
      width: 120,
      ...getFilterDevices('last_activity'),
      render: (...record) => (
        <div className="actions">
          {record[1].last_activity || 'Nenhuma atividade registrada'}

          <Tooltip title="Opções">
            <Dropdown
              overlay={menu(record[1])}
              trigger={['click']}
              onClick={() => setFormValues(record[1])}
            >
              <MoreOutlined style={{ color: '#1890ff', fontSize: '25px' }} />
            </Dropdown>
          </Tooltip>
        </div>
      ),
    },
  ];

  const routes = [
    {
      path: 'index',
      breadcrumbName: 'Dispositivos',
    },
    {
      path: 'first',
      breadcrumbName: 'Gerenciamento',
    },
  ];

  useEffect(() => {
    if (loadMoreDevices) {
      setParams({ limit: 100, offset: offset + limit });
      dispatch(DevicesActions.async_get_all_devices(limit, offset));
      dispatch(DevicesActions.enable_load_more(false));
    }

    const devices = devicesList.filter((device) => !device.block_downlink);
    setActiveDevices(devices.length);

    if (!collapsed) {
      setNewClass('footer-opened');
    } else {
      setNewClass('footer-closed');
    }
  }, [clientsList, devicesList, formValues,
    state, dispatch, params, requestDevLoading, searchDevicesResult]);

  return (
    <Layout style={{ width: '100%' }}>
      <PageHeader
        className="site-page-header"
        title="Gerenciamento de Dispositivos"
        breadcrumb={{ routes }}
      />

      <Content>
        <Card
          bordered={false}
          extra={[
            <Space>
              <Statistic
                title="Dispositivos ativos"
                value={activeDevices}
                valueStyle={{ color: '#3f8600' }}
                prefix={<ArrowUpOutlined />}
              />

              <Button type="link" onClick={() => {}}>
                <Link to="/device-register">
                  Novo Dispositivo
                  {' '}
                  <PlusOutlined />
                </Link>
              </Button>

              {/* <Button type="link" onClick={() => {}}>
                <ReloadOutlined />
                {' '}
                Atualizar
              </Button> */}
            </Space>,
          ]}
        >
          <Card>
            <Collapse defaultActiveKey={['1']} ghost>
              <Panel header="Filtro de pesquisa" key="1">
                <AdvancedFilter onSearch={onSearch} onCleanFilters={onCleanFilters} />
              </Panel>
            </Collapse>
          </Card>

          <Table
            columns={columns}
            rowSelection={rowSelection}
            dataSource={searchDevicesResult.length > 0 ? searchDevicesResult : devicesList}
            pagination={{ pageSize: 20, position: ['bottomCenter'] }}
            loading={!(devicesList.length > 0)}
            scroll={{ y: 500 }}
            footer={() => (
              <>
                <Button
                  type="link"
                  // onClick={() => dispatch(DevicesActions.enable_load_more(true))}
                  onClick={handleLoadMoreDevices}
                >
                  <PlusOutlined />
                  Carregar mais
                </Button>
              </>
            )}
          />
        </Card>

      </Content>

      <DownlinkMessageModal
        dev_eui={formValues.dev_eui || ''}
        visible={modalsVisibility.downlink_message_modal}
        onCancel={() => setModalsVisibility({ downlink_message_modal: false })}
        key="send-message-modal"
      />

      <UpdateDeviceForm
        visible={visible}
        key={`${formValues.key}-form-updade`}
        devInfo={formValues}
        onUpdate={handleUpdateDevice}
        onCancel={() => {
          setVisible(false);
        }}
        clients={clientsList}
      />

      <TableFooter
        className={newClass}
        entitySelected={devicesSelected}
        entityName="dispositivos"
        onActivation={() => handleActivation(devicesSelected, null, 'True')}
        onDeactivation={() => handleActivation(devicesSelected, null, 'False')}
        onDelete={() => handleRemoveDevice(devicesSelected, formValues.dev_eui)}
      />

      <LoadingDiv visibility={requestDevLoading} />
    </Layout>
  );
}

export default DevicesManagement;
