import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Form,
  Modal,
  Input,
  Button,
} from 'antd';
import {
  CloseCircleOutlined,
} from '@ant-design/icons';

import { TagsActions } from '../../../actions';

function NewTagModal({ visible, onCancel, client_id }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  async function onFinish({ name }) {
    dispatch(TagsActions.async_add_tag({ client_id, name }));
  }

  return (
    <Modal
      title="Cadastrar tag"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closeIcon={<CloseCircleOutlined style={{ color: 'red' }} />}
      keyboard
      width={500}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item label="Tag" name="name">
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
            Registrar
          </Button>
        </Form.Item>

      </Form>
    </Modal>
  );
}

NewTagModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  client_id: PropTypes.number.isRequired,
};

export default NewTagModal;
