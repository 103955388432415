import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Tag,
  Row,
  Col,
  Pagination,
  Button,
  Skeleton,
  Card,
  Modal,
  Typography,
  Select,
  Form,
  Tooltip,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  ClearOutlined,
  SearchOutlined,
} from '@ant-design/icons';

// import { Container } from './styles';
import { ConnectionsActions, ClientsActions } from '../../../actions';
import UpdateConnectionsModal from '../update';

function ConnectionTable({ dataSource, totalItems }) {
  const { confirm } = Modal;
  const { Paragraph } = Typography;
  const [form] = Form.useForm();
  const { Option } = Select;

  const { u_type } = useSelector((state) => state.AuthReducer);
  const { clientsList } = useSelector((state) => state.ClientsReducer);
  const { loadingSearch } = useSelector((state) => state.ConnectionsReducer);

  const [updateConnectionModal, setUpdateConnectionModal] = useState(false);
  const [connectionData, setConnectionData] = useState({ ConnectionModel: { id: 1 } });
  const [currentPage, setCurrentPage] = useState(1);
  const [clientId, setClientId] = useState(null);
  const [connectionType, setConnectionType] = useState(null);
  const dispatch = useDispatch();

  const filterOptions = [
    { value: 'Lora data', field: 'lora' },
    { value: 'Radio data', field: 'radio' },
    { value: 'Duplicates', field: 'duplicate' },
    { value: 'Incluir tags', field: 'with_tags' },
  ];

  const regularCardGrid = {
    padding: '2px',
    borderRadius: '8px',
    width: 230,
  };

  const styles = {
    enabledConnection: {
      ...regularCardGrid,
      borderColor: '#1890ff',
    },
    disabledConnection: {
      ...regularCardGrid,
      borderColor: '#ff4d4f',
    },
  };

  async function onDeleteConnection(id) {
    confirm({
      title: 'Deseja deletar a conexão?',
      icon: <ExclamationCircleOutlined />,

      onOk() {
        dispatch(ConnectionsActions.async_delete_connection(id));
      },
    });
  }

  function onChangePage(current, pageSize) {
    setCurrentPage(current);

    const offset = current === 1 ? 0 : (current - 1) * pageSize;
    dispatch(ConnectionsActions.set_loading_search(true));
    dispatch(ConnectionsActions.async_get_connections(pageSize, offset, connectionType, clientId));
  }

  function handleSearchConnection({ client_id, connection_type }) {
    setCurrentPage(1);
    setClientId(client_id);
    setConnectionType(connection_type);
    dispatch(ConnectionsActions.set_loading_search(true));

    dispatch(ConnectionsActions.async_get_connections(10, 0, connection_type, client_id));
  }

  function handleClearSearch() {
    form.resetFields();
    setClientId(null);
    setConnectionType(null);
    setCurrentPage(1);
    dispatch(ConnectionsActions.set_loading_search(true));

    dispatch(ConnectionsActions.async_get_connections(10, 0));
  }

  useEffect(() => {}, [connectionData, updateConnectionModal, dataSource]);

  useEffect(() => {
    if ((u_type === 1 || u_type === '1') && clientsList.length < 1) {
      dispatch(ClientsActions.async_get_clients(100, 0));
    }
  }, [clientsList]);

  useEffect(() => {}, [clientId]);

  return (
    <Card bordered={false}>
      {
        (u_type === 1 || u_type === '1') && (

          <Card>
            <Form
              name="advanced_search"
              className="ant-advanced-search-form"
              key="filter-query"
              form={form}
              layout="horizontal"
              onFinish={handleSearchConnection}
            >
              <Row gutter={16}>
                <Col>
                  <Form.Item label="Cliente" name="client_id">
                    <Select
                      showSearch
                      allowClear
                      style={{ minWidth: 200 }}
                      optionFilterProp="children"
                      filterOption={
                  (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}
                    >
                      {clientsList.map(
                        (client) => (
                          <Option key={client.id} value={client.id}>
                            {client.company_name}
                          </Option>
                        ),
                      )}
                    </Select>
                  </Form.Item>
                </Col>

                <Col>
                  <Form.Item label="Tipo de conexão" name="connection_type">
                    <Select allowClear style={{ minWidth: '80px' }}>
                      <Option key="mqtt" value="mqtt">MQTT</Option>
                      <Option key="http2" value="http2">HTTP</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      <SearchOutlined />
                      Pesquisar
                    </Button>
                  </Form.Item>
                </Col>

                <Col>

                  <Button
                    type="default"
                    onClick={handleClearSearch}
                  >
                    <ClearOutlined />
                    Limpar
                  </Button>

                </Col>
              </Row>
            </Form>
          </Card>
        )
      }

      <Row
        gutter={{
          xs: 8, sm: 16, md: 24, lg: 32,
        }}
      >
        { dataSource.length > 0 ? dataSource.map((data) => (
          <Col className="gutter-row" style={{ margin: '15px 0 15px 0' }}>
            { loadingSearch === false
              ? (
                <Card
                  key={`${data.ConnectionModel.id}fnsaofspamfpsan`}
                  hoverable
                  title={(
                    <Tag color="blue">
                      {
                      data.ConnectionModel.connection_type.toUpperCase()
                    }
                    </Tag>
                )}
                  style={
                    data.FilterModel.is_disabled === true
                      ? styles.disabledConnection : styles.enabledConnection
                  }
                  actions={[
                    <Tooltip title="Editar">
                      <EditOutlined onClick={() => {
                        setUpdateConnectionModal(true);
                        setConnectionData(data);
                        setConnectionData(data);
                        dispatch(ConnectionsActions.set_types(data.FilterModel.types));

                        const options_list = filterOptions.filter(
                          (i) => data.FilterModel[i.field] === true,
                        ).map((k) => k.value);

                        dispatch(ConnectionsActions.set_options(
                          options_list,
                        ));

                        dispatch(ConnectionsActions.set_connection_values(
                          data.ConnectionModel, data.FilterModel,
                        ));

                        dispatch(ConnectionsActions.set_devices(data.FilterModel.devices));
                        dispatch(ConnectionsActions.set_gateways(data.FilterModel.gateways));
                        dispatch(
                          ConnectionsActions.set_applications(data.FilterModel.applications),
                        );
                      }}
                      />
                    </Tooltip>,
                    <Tooltip title="Deletar">
                      <DeleteOutlined
                        color="red"
                        onClick={() => { onDeleteConnection(data.ConnectionModel.id); }}
                      />
                    </Tooltip>,
                  ]}
                  extra={(
                    <span>
                      { data.ConnectionModel
                        .created_at.slice(0, 10)}
                    </span>
                )}
                >
                  <Paragraph
                    ellipsis={{
                      rows: 1,
                      expandable: false,
                      suffix: '  ',
                    }}
                  >
                    {data.ConnectionModel.description}
                  </Paragraph>
                </Card>
              )
              : (
                <Card style={regularCardGrid}>
                  <Skeleton />
                </Card>
              )}

          </Col>
        ))
          : <Skeleton />}
      </Row>

      <Pagination
        total={totalItems}
        current={currentPage}
        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
        showSizeChanger
        defaultCurrent={1}
        onChange={onChangePage}
      />

      {
        dataSource.length > 0 ? (
          <UpdateConnectionsModal
            key={`${connectionData.ConnectionModel.id}dsaosafoiasnofin`}
            data={connectionData}
            visible={updateConnectionModal}
            onCancel={() => setUpdateConnectionModal(false)}
          />
        ) : <></>
}
    </Card>
  );
}

ConnectionTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  dataSource: PropTypes.array.isRequired,
  totalItems: PropTypes.number.isRequired,
};

export default ConnectionTable;
