// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/antd/dist/antd.css";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/nasa.jpg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login-container {\n  display: flex;\n  flex-direction: column;\n  width: 350px; \n  position: relative;\n  margin: auto;\n  top: 250px;\n  /* float: right;\n  margin-right: 100px; */\n}\n\n.background-container {\n  position: fixed;\n  width: 100%;\n  height: 100vh;\n  left: 0;\n  top: 0;\n  margin-right: 0;\n  object-fit: cover;\n\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: 100% 100vh;\n}", "",{"version":3,"sources":["webpack://src/views/Login/test.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,UAAU;EACV;wBACsB;AACxB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,aAAa;EACb,OAAO;EACP,MAAM;EACN,eAAe;EACf,iBAAiB;;EAEjB,mDAAwC;EACxC,4BAA4B;EAC5B,2BAA2B;AAC7B","sourcesContent":["@import '~antd/dist/antd.css';\n\n.login-container {\n  display: flex;\n  flex-direction: column;\n  width: 350px; \n  position: relative;\n  margin: auto;\n  top: 250px;\n  /* float: right;\n  margin-right: 100px; */\n}\n\n.background-container {\n  position: fixed;\n  width: 100%;\n  height: 100vh;\n  left: 0;\n  top: 0;\n  margin-right: 0;\n  object-fit: cover;\n\n  background: url('../../assets/nasa.jpg');\n  background-repeat: no-repeat;\n  background-size: 100% 100vh;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
