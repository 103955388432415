import { message } from 'antd';
import { put } from 'redux-saga/effects';

import api from '../../services/api';
import { MessagesActions } from '../../actions';

export function* getMessages({ dev_eui, filters }) {
  const hide = message.loading('buscando mensagens do dispositivo...');

  try {
    const { data } = yield api.get(`messages/${dev_eui}`, { params: filters })
      .then((response) => {
        hide();
        return response;
      });
    const { messages } = data;

    if (!messages.length) {
      message.warning('Nenhuma mensagem foi encontrada.');
    } else {
      messages.map((i) => Object.assign(i, { key: `${i.type}_${i.meta.packet_id}` }));
    }

    yield put(MessagesActions.save_messages(messages));
  } catch (warning) {
    message.warning('Ocorreu erro durante a busca!');
  }
}
