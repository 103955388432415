export const ACTION_TYPES = {
  SAVE_CONNECTION_LIST: 'SAVE_CONNECTION_LIST',
  ADD_CONNECTION: 'ADD_CONNECTION',
  UPDATE_CONNECTION: 'UPDATE_CONNECTION',
  DELETE_CONNECTION: 'DELETE_CONNECTION',
  SHOW_ONE_CONNECTION: 'SHOW_ONE_CONNECTION',
  CLEAR_CONNECTION_LIST: 'CLEAR_CONNECTION_LIST',
  LOAD_MORE_CONNECTIONS: 'LOAD_MORE_CONNECTIONS',
  SET_CONNECTION_VALUES: 'SET_CONNECTION_VALUES',
  SET_TYPES: 'SET_TYPES',
  SET_OPTIONS: 'SET_OPTIONS',
  SET_DEVICES: 'SET_DEVICES',
  SET_GATEWAYS: 'SET_GATEWAYS',
  SET_APPLICATIONS: 'SET_APPLICATIONS',
  LOADING_SEARCH: 'LOADING_SEARCH',
  SET_FIRST_LOADING: 'SET_FIRST_LOADING',

  // Ações assíncronas
  ASYNC_GET_CONNECTIONS: 'ASYNC_GET_CONNECTIONS',
  ASYNC_GET_CONNECTIONS_BY_FILTERS: 'ASYNC_GET_CONNECTIONS_BY_FILTERS',
  ASYNC_ADD_CONNECTION: 'ASYNC_ADD_CONNECTION',
  ASYNC_UPDATE_CONNECTIONS: 'ASYNC_UPDATE_CONNECTIONS',
  ASYNC_DELETE_CONNECTIONS: 'ASYNC_DELETE_CONNECTIONS',
};

export const set_first_loading = (value) => ({
  type: ACTION_TYPES.SET_FIRST_LOADING,
  value,
});

export const set_loading_search = (loading) => ({
  type: ACTION_TYPES.LOADING_SEARCH,
  loading,
});

export const set_devices = (devices_list) => ({
  type: ACTION_TYPES.SET_DEVICES,
  devices_list,
});

export const set_gateways = (gateways_list) => ({
  type: ACTION_TYPES.SET_GATEWAYS,
  gateways_list,
});

export const set_applications = (applications_list) => ({
  type: ACTION_TYPES.SET_APPLICATIONS,
  applications_list,
});

export const set_options = (options_list) => ({
  type: ACTION_TYPES.SET_OPTIONS,
  options_list,
});

export const set_types = (types_list) => ({
  type: ACTION_TYPES.SET_TYPES,
  types_list,
});

export const set_connection_values = (connection, filter) => ({
  type: ACTION_TYPES.SET_CONNECTION_VALUES,
  connection,
  filter,
});

export const save_connection_list = (connection_list, total) => ({
  type: ACTION_TYPES.SAVE_CONNECTION_LIST,
  connection_list,
  total,
});

export const clear_connection_list = () => ({
  type: ACTION_TYPES.CLEAR_CONNECTION_LIST,
});

export const add_connection = (new_connection) => ({
  type: ACTION_TYPES.ADD_CONNECTION,
  new_connection,
});

export const delete_connection = (id) => ({
  type: ACTION_TYPES.DELETE_CONNECTION,
  id,
});

export const update_connection = (id, updated_connection, updated_filter) => ({
  type: ACTION_TYPES.UPDATE_CONNECTION,
  id,
  updated_connection,
  updated_filter,
});

export const async_add_connection = (connections, filters, client_id = null) => ({
  type: ACTION_TYPES.ASYNC_ADD_CONNECTION,
  connections,
  filters,
  client_id,
});

export const async_delete_connection = (id) => ({
  type: ACTION_TYPES.ASYNC_DELETE_CONNECTIONS,
  id,
});

export const async_update_connection = (id, updated_connection, updated_filter) => ({
  type: ACTION_TYPES.ASYNC_UPDATE_CONNECTIONS,
  id,
  updated_connection,
  updated_filter,
});

export const async_get_connections = (
  limit = 10, offset = 0, connection_type = null, client_id = null,
) => ({
  type: ACTION_TYPES.ASYNC_GET_CONNECTIONS,
  limit,
  offset,
  connection_type,
  client_id,
});

export const async_get_connections_by_filter = (id, connection_type, limit, offset) => ({
  type: ACTION_TYPES.ASYNC_GET_CONNECTIONS_BY_FILTERS,
  id,
  connection_type,
  limit,
  offset,
});
