import React, { useEffect, useState } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import PropTypes from 'prop-types';
import {
  Form,
  Modal,
  Input,
  Button,
  Select,
  Layout,
  Divider,
  Space,
  Typography,
  Switch,
  Tag,
} from 'antd';
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { EditableGroup } from '../../../components';
import { ConnectionsActions } from '../../../actions';

function UpdateConnectionsModal({
  visible, onCancel, data,
}) {
  const { Content } = Layout;
  const { Option } = Select;
  const { form } = Form;
  const { CheckableTag } = Tag;
  const dispatch = useDispatch();

  const { tagsList } = useSelector((state) => state.TagsReducer);

  const {
    initialConnectionValues,
    types,
    optionsList,
    devices,
    gateways,
    applications,
  } = useSelector((state) => state.ConnectionsReducer);

  const filter = { ...data.FilterModel };
  filter.filter_description = filter.description;
  delete filter.description;

  const initialValues = { ...data.ConnectionModel, ...filter };

  const [tags, setTags] = useState(tagsList.map((tag) => tag.name));
  const [appEuiList, setAppEuiList] = useState([...applications]);
  const [devices2, setDevices] = useState([...devices]);
  const [gatewaysList, setGatewaysList] = useState([...gateways]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [name, setName] = useState('');
  const messageTypes = ['join_request', 'uplink', 'downlink', 'error', 'warning', 'info', 'location', 'status_response', 'downlink_request'];
  const AditionalOptions = ['Duplicates', 'Radio data', 'Lora data', 'Incluir tags'];
  let index = 0;

  function onFinish(values) {
    const connection_data = {
      auth_header: values.auth_header || null,
      url: values.url || null,
      client_id_label: values.client_id_label || null,
      topic_down: values.topic_down || null,
      topic_up: values.topic_up || null,
      uri: values.uri || null,
      connection_type: values.connection_type,
      description: values.description,
    };

    const filter_data = {
      applications: appEuiList || applications,
      description: values.filter_description || null,
      devices: devices2 || devices,
      duplicate: optionsList.includes('Duplicates'),
      gateways: gatewaysList || gateways,
      lora: optionsList.includes('Lora data'),
      radio: optionsList.includes('Radio data'),
      tags: values.tags || [],
      types,
      with_tags: optionsList.includes('Incluir tags'),
      is_disabled: isDisabled,
    };

    // console.log({ connection_data, filter_data });
    dispatch(ConnectionsActions.async_update_connection(
      data.ConnectionModel.id,
      connection_data,
      filter_data,
    ));

    setTimeout(() => onCancel(), 3);
  }

  function handleTagChange(tag, checked) {
    const nextSelectedType = checked
      ? [...types, tag] : types.filter((t) => t !== tag);
    dispatch(ConnectionsActions.set_types(nextSelectedType));
  }

  function handleAditionalOptionChange(item, checked) {
    const nextSelectedOptions = checked
      ? [...optionsList, item] : optionsList.filter((t) => t !== item);
    dispatch(ConnectionsActions.set_options(nextSelectedOptions));
  }

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const addItem = (e) => {
    e.preventDefault();
    index += 1;
    setTags([...tags, name || `New item ${index}`]);
    setName('');
  };

  useEffect(() => {
  }, [initialValues, optionsList, types]);

  useEffect(() => {
    setIsDisabled(initialConnectionValues.is_disabled);
  }, [initialConnectionValues]);

  return (
    <>
      {
        Object.keys(data).length > 0 && (
        <Modal
          key={`${data.ConnectionModel.id}dsadsadas`}
          title="Editar conexão"
          visible={visible}
          onCancel={onCancel}
          footer={null}
          closeIcon={<CloseCircleOutlined style={{ color: 'red' }} />}
          keyboard
          width={500}
        >
          <Layout style={{ background: '#FFFF' }}>
            <Content>
              { Object.keys(data).length > 0 && (

              <Form
                key={`${data.ConnectionModel.id}dsadsadas`}
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={{ ...initialValues }}
              >
                <div className="space-align-block">
                  <Space align="baseline">
                    <h2>Detalhes do filtro</h2>
                    <Form.Item
                      name="is_disabled"
                    >
                      <Switch
                        checkedChildren="Habilitado"
                        unCheckedChildren="Desabilitado"
                        checked={!isDisabled}
                        onChange={(checked) => setIsDisabled(!checked)}

                      />
                    </Form.Item>
                  </Space>
                </div>

                <Form.Item
                  label="Description"
                  name="filter_description"
                  requiredMark="opcional"
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Tags dos dispositivos"
                  name="tags"
                >
                  <Select
                    mode="tags"
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />
                        <Space align="center" style={{ padding: '0 8px 4px' }}>
                          <Input placeholder="Please enter item" value={name} onChange={onNameChange} />
                          <Typography.Link onClick={addItem} style={{ whiteSpace: 'nowrap' }}>
                            <PlusOutlined />
                            {' '}
                            Adicionar tag
                          </Typography.Link>
                        </Space>
                      </>
                    )}
                  >
                    {tags.map((item) => (
                      <Option key={item}>{item}</Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Dispositivos"
                  name="devices"
                  key="devices"
                  tooltip="Adicione apenas o dev_eui do dispositivo."
                >
                  <EditableGroup
                    key="editable--devices-group"
                    updateHandler={(item) => setDevices(item)}
                    items={devices2}
                  />
                </Form.Item>

                <Form.Item
                  label="Aplicações"
                  name="applications"
                  tooltip="Adicione apenas application(s) id."
                >
                  <EditableGroup
                    key="editable--appeui-group"
                    updateHandler={(item) => setAppEuiList(item)}
                    items={appEuiList}
                  />
                </Form.Item>

                <Form.Item
                  label="Gateways"
                  name="gateways"
                >
                  <EditableGroup
                    key="editable-gatway-group"
                    updateHandler={(item) => setGatewaysList(item)}
                    items={gatewaysList}
                  />
                </Form.Item>

                <Form.Item
                  label="Tipos de mensagens"
                  name="types"
                  key="dasnibfasifnsibun"
                >
                  {messageTypes.map((messageType) => (
                    <CheckableTag
                      key={messageType}
                      checked={types.indexOf(messageType) > -1}
                      onChange={(checked) => handleTagChange(messageType, checked)}
                    >
                      {messageType}
                    </CheckableTag>
                  ))}
                </Form.Item>

                <Form.Item label="Opções" name="options">
                  {AditionalOptions.map((option) => (
                    <CheckableTag
                      key={option}
                      checked={optionsList.indexOf(option) > -1}
                      onChange={(checked) => handleAditionalOptionChange(option, checked)}
                    >
                      {option}
                    </CheckableTag>
                  ))}
                </Form.Item>

                <Divider />

                <h2>Detalhes da conexão</h2>

                <Form.Item
                  label="Tipo da conexão"
                  name="connection_type"
                >
                  <Select disabled>
                    <Option key="mqtt" value="mqtt">MQTT</Option>
                    <Option key="http2" value="http2">HTTP</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Description"
                  name="description"
                  requiredMark="opcional"
                >
                  <Input style={{ width: '50%' }} />
                </Form.Item>

                <Form.Item
                  label="URL da aplicação"
                  name="url"
                  hidden={initialValues.connection_type === 'mqtt'}
                  tooltip="Você pode anexar o link da sua aplicação."
                  rules={[
                    {
                      required: initialValues.connection_type === 'http2',
                    },
                  ]}
                >
                  <Input placeholder="https://exemplo.com" />
                </Form.Item>

                <Form.Item
                  label="Authorization header"
                  name="auth_header"
                  hidden={initialValues.connection_type === 'mqtt'}
                  requiredMark="opcional"
                >
                  <Input maxLength={100} />
                </Form.Item>

                <Form.Item
                  label="Network Server publish topic"
                  name="topic_up"
                  hidden={initialValues.connection_type === 'http2'}
                  rules={[
                    {
                      required: initialValues.connection_type === 'mqtt',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="URI"
                  name="uri"
                  hidden={initialValues.connection_type === 'http2'}
                  rules={[
                    {
                      required: initialValues.connection_type === 'mqtt',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Network Server subscribe topic"
                  name="topic_down"
                  requiredMark="opcional"
                  hidden={initialValues.connection_type === 'http2'}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Client id"
                  name="client_id_label"
                  requiredMark="opcional"
                  hidden={initialValues.connection_type === 'http2'}
                >
                  <Input />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: '100%' }}
                  >
                    Salvar
                  </Button>
                </Form.Item>
              </Form>
              )}

            </Content>
          </Layout>
        </Modal>
        )
    }
    </>

  );
}

UpdateConnectionsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  data: PropTypes.objectOf.isRequired,
  // types: PropTypes.func.isRequired,
};

export default UpdateConnectionsModal;
