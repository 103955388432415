import React from 'react';
import { Tag, Input } from 'antd';
import { TweenOneGroup } from 'rc-tween-one';
import { PlusOutlined } from '@ant-design/icons';

class EditableGroup extends React.Component {
  constructor(props) {
    super(props);

    this.handleUpdateItem = props.updateHandler.bind(this);

    this.state = {
      items: props.items,
      inputVisible: false,
      inputValue: '',
    };
  }

  handleClose = (removedItem) => {
    const { items } = this.state;
    const updatedItems = items.filter((tag) => tag !== removedItem);
    this.handleUpdateItem(updatedItems);
    this.setState({ items: updatedItems });
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    const { inputValue } = this.state;
    let { items } = this.state;
    if (inputValue && items.indexOf(inputValue) === -1) {
      items = [...items, inputValue];
    }

    this.handleUpdateItem(items);

    this.setState({
      items,
      inputVisible: false,
      inputValue: '',
    });
  };

  saveInputRef = (input) => {
    this.input = input;
  };

  forMap = (item) => {
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          this.handleClose(item);
        }}
      >
        {item}
      </Tag>
    );
    return (
      <span key={item} style={{ display: 'inline-block' }}>
        {tagElem}
      </span>
    );
  };

  render() {
    const { items, inputVisible, inputValue } = this.state;
    const tagChild = items.map(this.forMap);

    return (
      <div>

        <div style={{ marginBottom: 16 }}>
          <TweenOneGroup
            enter={{
              scale: 0.8,
              opacity: 0,
              type: 'from',
              duration: 100,
              onComplete: (e) => {
                e.target.style = '';
              },
            }}
            leave={{
              opacity: 0, width: 0, scale: 0, duration: 200,
            }}
            appear={false}
          >
            {tagChild}
          </TweenOneGroup>
        </div>
        {inputVisible && (
          <Input
            ref={this.saveInputRef}
            type="text"
            size="small"
            style={{ width: 78 }}
            value={inputValue}
            onChange={this.handleInputChange}
            onBlur={this.handleInputConfirm}
            onPressEnter={this.handleInputConfirm}
          />
        )}
        {!inputVisible && (
          <Tag onClick={this.showInput} className="site-tag-plus">
            <PlusOutlined />
            {' '}
            Novo item
          </Tag>
        )}
      </div>
    );
  }
}

export default EditableGroup;
