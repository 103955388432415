import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Collapse,
  Card,
  Modal,
  Space,
  Tag,
  Descriptions,
  Tooltip,
} from 'antd';
import {
  CloseCircleOutlined,
  RiseOutlined,
  ArrowUpOutlined,
  WifiOutlined,
  SettingOutlined,
  FieldTimeOutlined,
  ArrowDownOutlined,
  ToolOutlined,
} from '@ant-design/icons';

import { RetrieveMessagesForm } from '../index';

function MessagesModal({
  visible, onCancel, onCleanFilters,
}) {
  const { Panel } = Collapse;
  const { messagesList } = useSelector((state) => state.MessagesReducer);
  const color = {
    uplink: 'green',
    donwlink: 'orange',
    donwlink_request: 'gold',
    join_request: 'cyan',
    status_response: 'geekblue',
    error: 'red',
  };

  const params_type = (message_type, data) => (
    <Descriptions label="Params">
      {message_type === 'error' && (
        <ul>
          <li>
            <strong>code:</strong>
            {' '}
            {data.code || '--'}
          </li>

          <li>
            <strong>message:</strong>
            {' '}
            {data.message || '--'}
          </li>
        </ul>
      )}
      {['uplink', 'downlink'].includes(message_type) && (
        <ul>
          <li>
            <strong>counter_up:</strong>
            {' '}
            {message_type === 'uplink' ? data.counter_up || '0' : data.counter_down || '0'}
          </li>

          <li>
            <strong>duplicate:</strong>
            {' '}
            {data.duplicate ? 'True' : 'False'}
          </li>

          <li>
            <strong>encrypted_payload:</strong>
            {' '}
            {data.encrypted_payload || ' -- '}
          </li>

          <li>
            <strong>payload:</strong>
            {' '}
            {data.payload || ' -- '}
          </li>

          <li>
            <strong>port:</strong>
            {' '}
            {data.port || ' -- '}
          </li>

          <li>
            <strong>rx_time:</strong>
            {' '}
            {data.rx_time || ' -- '}
          </li>
        </ul>
      )}
      {message_type === 'downlink_request' && (
        <ul>
          <li>
            <strong>tx_time:</strong>
            {' '}
            {data.tx_time || ' -- '}
          </li>
          <li>
            <strong>counter_down:</strong>
            {' '}
            {data.counter_down || ' -- '}
          </li>
          <li>
            <strong>max_size:</strong>
            {' '}
            {data.max_size || ' -- '}
          </li>
        </ul>
      )}
      {message_type === 'join_request' && (
        <ul>
          <li>
            <strong>dev_nonce:</strong>
            {' '}
            {data.dev_nonce || ' -- '}
          </li>
          <li>
            <strong>dev_eui:</strong>
            {' '}
            {data.dev_eui || ' -- '}
          </li>
          <li>
            <strong>dev_addr:</strong>
            {' '}
            {data.dev_addr || ' -- '}
          </li>
          <li>
            <strong>net_id:</strong>
            {' '}
            {data.net_id || ' -- '}
          </li>
        </ul>
      )}
      {message_type === 'status_response' && (
        <ul>
          <li>
            <strong>battery:</strong>
            {' '}
            {data.battery || ' -- '}
          </li>
          <li>
            <strong>snr:</strong>
            {' '}
            {data.snr || ' -- '}
          </li>
          <li>
            <strong>rx_time:</strong>
            {' '}
            {data.rx_time || ' -- '}
          </li>
        </ul>
      )}
    </Descriptions>
  );

  return (
    <Modal
      title="Mensagens"
      key="get-messages"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closeIcon={<CloseCircleOutlined style={{ color: 'red' }} />}
      keyboard
      width={1200}
    >
      <Card bordered={false}>
        <Card bordered={false}>
          <RetrieveMessagesForm key="fsoaoajos" visible={visible} onCleanFilters={onCleanFilters} />
        </Card>

        <Card bordered={false}>
          <Collapse defaultActiveKey={['2']} ghost>
            {
              messagesList.map((message) => (
                <Panel
                  key={message.key}
                  header={[
                    <Space>
                      <Tooltip title="Insert time">
                        <Tag color="blue">
                          <FieldTimeOutlined />
                          {' '}
                          {message.insert_time}
                        </Tag>
                      </Tooltip>

                      <Tooltip title="Message type">
                        <Tag color={color[message.type]}>
                          {message.type.toLowerCase() === 'uplink' && <ArrowUpOutlined />}
                          {message.type.toLowerCase() === 'downlink' && <ArrowDownOutlined />}
                          {message.type.toLowerCase() === 'downlink_request' && <ArrowDownOutlined />}
                          {message.type.toLowerCase() === 'error' && <CloseCircleOutlined />}
                          {message.type.toLowerCase() === 'status_response' && <ToolOutlined />}
                          {message.type.toLowerCase() === 'join_request' && <ToolOutlined />}
                          {' '}
                          {message.type}
                        </Tag>
                      </Tooltip>

                      {['uplink', 'downlink'].includes(message.type.toLowerCase())
                        && (
                          <Tooltip title="Device eui">
                            <Tag color="magenta">{message.meta.device}</Tag>
                          </Tooltip>
                        )}

                      {['uplink', 'downlink'].includes(message.type.toLowerCase())
                        && (
                          <Tooltip title="Device address">
                            <Tag color="cyan">{message.meta.device_addr}</Tag>
                          </Tooltip>
                        )}

                      {['uplink', 'downlink'].includes(message?.type.toLowerCase())
                        && (
                          <Tooltip title="Application">
                            <Tag color="volcano">{message?.meta?.application}</Tag>
                          </Tooltip>
                        )}

                      {(['uplink', 'downlink'].includes(message?.type.toLowerCase()))
                        && (
                          <Tooltip title="Counter up">
                            <Tag color="green">
                              <RiseOutlined />
                              {' '}
                              {message?.params?.counter_up || '0'}
                            </Tag>
                          </Tooltip>
                        )}

                      {['uplink', 'downlink'].includes(message?.type.toLowerCase())
                        && (
                          <Tooltip title="Radio freq">
                            <Tag color="green">
                              <WifiOutlined />
                              {' '}
                              {' '}
                              {' '}
                              {message?.params?.radio?.freq || ' -- '}
                            </Tag>
                          </Tooltip>
                        )}

                      {['uplink', 'downlink'].includes(message?.type.toLowerCase())
                        && (
                          <Tooltip title="rssi">
                            <Tag color="purple">
                              <SettingOutlined />
                              {' '}
                              {' '}
                              {' '}
                              {message?.params?.radio?.hardware?.rssi || ' -- '}
                            </Tag>
                          </Tooltip>
                        )}

                      {['uplink', 'downlink'].includes(message.type.toLowerCase())
                        && (
                          <Tooltip title="Datarate">
                            <Tag color="purple">
                              <SettingOutlined />
                              {' '}
                              {' '}
                              {' '}
                              {message?.params?.radio?.datarate || '0'}
                            </Tag>
                          </Tooltip>
                        )}

                    </Space>]}
                >
                  <Descriptions bordered={false}>
                    <Descriptions.Item label="Type">{message?.type || '--'}</Descriptions.Item>

                    <Descriptions.Item label="Insert time">{message?.insert_time || '--'}</Descriptions.Item>

                    <Descriptions label="Meta">
                      <ul>
                        <li>
                          {' '}
                          <strong>application:</strong>
                          {' '}
                          {message?.meta?.application || ' -- '}
                        </li>
                        <li>
                          <strong>packet_id:</strong>
                          {' '}
                          {message?.meta?.packet_id || ' -- '}
                        </li>

                        <li>
                          <strong>device_addr: </strong>
                          {message?.meta?.device_addr || ' -- '}
                        </li>

                        <li>
                          <strong>iime:</strong>
                          {message?.meta?.time || ' -- '}
                        </li>

                        <li>
                          <strong>packet_hash: </strong>
                          {message?.meta?.packet_hash || ' -- '}
                        </li>

                        <li>
                          <strong>device</strong>
                          {' '}
                          {message.meta.device || ' -- '}
                        </li>

                        <li>
                          <strong>gateway</strong>
                          {message.meta.gateway || ' -- '}
                        </li>

                        <li>
                          <strong>network:</strong>
                          {' '}
                          {message.meta.network || ' -- '}
                        </li>
                      </ul>
                    </Descriptions>

                    {params_type(message.type, message.params)}

                    {(['uplink', 'downlink'].includes(message.type.toLowerCase()))
                      && (
                        <Descriptions.Item label="Radio">
                          <ul>
                            <li>
                              <strong>datarate:</strong>
                              {' '}
                              {message.params.radio.datarate || '0'}
                            </li>
                            <li>
                              <strong>delay:</strong>
                              {' '}
                              {message.params.radio.delay || ' -- '}
                            </li>
                            <li>
                              <strong>freq:</strong>
                              {' '}
                              {message.params.radio.freq || ' -- '}
                            </li>
                            <li>
                              <strong>size:</strong>
                              {' '}
                              {message.params.radio.size || ' -- '}
                            </li>
                            <li>
                              <strong>time:</strong>
                              {' '}
                              {message.params.radio.time || ' -- '}
                            </li>
                            <li>
                              <strong>datr:</strong>
                              {' '}
                              {message.params.radio.datr || ' -- '}
                            </li>
                          </ul>
                        </Descriptions.Item>
                      )}

                    {(['uplink', 'downlink'].includes(message.type.toLowerCase()))
                      && (
                        <Descriptions.Item label="Modulation">
                          <ul>
                            <li>
                              <strong>bandwidth:</strong>
                              {' '}
                              {message.params.radio.modulation.bandwidth || ' -- '}
                            </li>
                            <li>
                              <strong>coderate:</strong>
                              {' '}
                              {message.params.radio.modulation.coderate || ' -- '}
                            </li>
                            <li>
                              <strong>spreading:</strong>
                              {' '}
                              {message.params.radio.modulation.spreading || ' -- '}
                            </li>
                            <li>
                              <strong>type:</strong>
                              {' '}
                              {message.params.radio.modulation.type || ' -- '}
                            </li>
                            <li>
                              <strong>inverted:</strong>
                              {' '}
                              {message.params.radio.modulation.inverted || ' -- '}
                            </li>
                          </ul>
                        </Descriptions.Item>
                      )}

                    {(['uplink', 'downlink'].includes(message.type.toLowerCase()))
                      && (
                        <Descriptions.Item label="Hardware">
                          <ul>
                            <li>
                              <strong>chain:</strong>

                              {' '}
                              {message.params.radio.hardware.chain || ' -- '}
                            </li>
                            <li>
                              <strong>channel:</strong>

                              {' '}
                              {message.params.radio.hardware.channel || ' -- '}
                            </li>
                            <li>
                              <strong>rssi:</strong>
                              {' '}
                              {message.params.radio.hardware.rssi || ' -- '}
                            </li>
                            <li>
                              <strong>snr:</strong>
                              {' '}
                              {message.params.radio.hardware.snr || '0'}
                            </li>
                            <li>
                              <strong>status:</strong>
                              {' '}
                              {message.params.radio.hardware.status || ' -- '}
                            </li>
                            <li>
                              <strong>tmst:</strong>
                              {' '}
                              {message.params.radio.hardware.tmst || ' -- '}
                            </li>
                            <li>
                              <strong>power:</strong>
                              {' '}
                              {message.params.radio.hardware.power || ' -- '}
                            </li>
                          </ul>
                        </Descriptions.Item>
                      )}

                    {(['uplink', 'downlink'].includes(message.type.toLowerCase()))
                      && (
                        <Descriptions.Item label="GPS">
                          <ul>
                            <li>
                              <strong>alt:</strong>
                              {' '}
                              {message.params.radio.hardware.gps ? message.params.radio.hardware.gps.alt : ' -- '}
                            </li>
                            <li>
                              <strong>lat:</strong>
                              {' '}
                              {message.params.radio.hardware.gps ? message.params.radio.hardware.gps.lat : ' -- '}
                            </li>
                            <li>
                              <strong>lng:</strong>
                              {' '}
                              {message.params.radio.hardware.gps ? message.params.radio.hardware.gps.lng : ' -- '}
                            </li>
                          </ul>
                        </Descriptions.Item>
                      )}

                  </Descriptions>
                </Panel>
              ))
            }
          </Collapse>
        </Card>
      </Card>
    </Modal>
  );
}

MessagesModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCleanFilters: PropTypes.bool.isRequired,
};

export default MessagesModal;
